/** @jsx jsx */
import React, { Children } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const Grid = ({ data, children }) => {
  const theme = useTheme()
  const { containerStyle } = data
  const childCount = Children.count(children)
  const gridPercent = Math.floor(100 / childCount) - 2

  const wrappedChildren = Children.map(children, (child, index) => (
    <div
      key={`grid-item-${index}`}
      css={mq({
        flexBasis: `${gridPercent.toString()}%`,
        margin: '1em 0',
      })}
    >
      {child}
    </div>
  ))

  return (
    <section
      css={mq({
        display: 'flex',
        flexWrap: 'no-wrap',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'stretch',
        marginLeft: 'auto',
        width: '100%',
        ...containerStyle,
      })}
    >
      {wrappedChildren}
    </section>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// Samples
// /////////////////////////////////////////////////////////////////////////////

export default Grid
