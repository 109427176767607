import { useStaticQuery, graphql } from 'gatsby'

export const useImages = () => {
  const data = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          name
          publicURL
          ext
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  `)

  const allTheImages = {}
  data.allImages.nodes.forEach(
    image => (allTheImages[''.concat(image.name, image.ext)] = image)
  )

  return allTheImages
}

export default useImages
