/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const Accordions = ({ children, data }) => {
  const theme = useTheme()
  try {
    const containerStyle = data.containerStyle || {}
    return <section style={{ ...containerStyle }}>{children}</section>
  } catch {
    return <section>{children}</section>
  }
}

export default Accordions
