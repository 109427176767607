/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import COVID from './ACRC_Headshots_Final.gif'
import AskAccolade from './ask-accolade.gif'
// ////////////////////////////////////////////////////////////////////////////
// Component
// ////////////////////////////////////////////////////////////////////////////

const getGIF = path => {
  switch (path) {
    case 'COVID':
      return COVID
    case 'ask-accolade':
      return AskAccolade
    case 'default':
      return ''
  }
}

export const AnimatedGIF = ({ data }) => {
  const { path } = data
  const imgStyle = data.imgStyle || {}
  const containerStyle = data.containerStyle || {}

  return (
    <img
      style={{ ...containerStyle, ...imgStyle }}
      src={getGIF(path)}
      alt="gif"
    />
  )
}

export default AnimatedGIF
