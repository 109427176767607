/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import {
  Display,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  TitleXs,
  TitleXxs,
  Body,
} from '../RichText'
import { useDateFormatter } from '../../hooks'
import { SocialShare } from '../SocialShare'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const BlogHeader = ({ data }) => {
  const { title, date, slug, author, tags } = data
  const cleanDate = useDateFormatter(date)

  return (
    <section>
      {cleanDate ? <TitleXxs spacing="compact">{cleanDate}</TitleXxs> : null}
      {title ? <Display spacing="compact">{title}</Display> : null}
      {author ? <Body spacing="compact">By {author}</Body> : null}
      {tags && tags.length > 0 ? (
        <Body spacing="compact">Tags: {tags.join(', ')}</Body>
      ) : null}
      <SocialShare slug={slug} title={title} />
    </section>
  )
}

export default BlogHeader
