import React from 'react'

// ////////////////////////////////////////////////////////////////////////////
// Social Icons
// ////////////////////////////////////////////////////////////////////////////

const schema = {
  dark: ['#C4C7CB', '#40464B'],
  light: ['#ffffff', '#ffffff'],
}

export const Facebook = ({ color }) => (
  <svg
    id="share-icon-facebook"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g
      id="Ellipse_98"
      dataname="Ellipse 98"
      fill="none"
      stroke={
        schema[color] !== undefined ? schema[color][0] : schema['dark'][0]
      }
      strokeWidth="1.5"
    >
      <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
      <circle cx="17.5" cy="17.5" r="16.75" fill="none" />
    </g>
    <path
      id="Icon_awesome-facebook-f"
      dataname="Icon awesome-facebook-f"
      d="M10.054,9.491l.469-3.054H7.593V4.456a1.527,1.527,0,0,1,1.722-1.65h1.332V.206A16.244,16.244,0,0,0,8.282,0c-2.413,0-3.99,1.463-3.99,4.11V6.437H1.609V9.491H4.292v7.382h3.3V9.491Z"
      transform="translate(11.211 8.374)"
      fill={schema[color] !== undefined ? schema[color][1] : schema['dark'][1]}
    />
  </svg>
)

export const Instagram = ({ color }) => (
  <svg
    id="share-icon-instagram"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g
      id="Ellipse_100"
      dataname="Ellipse 100"
      fill="none"
      stroke={
        schema[color] !== undefined ? schema[color][0] : schema['dark'][0]
      }
      strokeWidth="1.5"
    >
      <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
      <circle cx="17.5" cy="17.5" r="16.75" fill="none" />
    </g>
    <path
      id="Icon_awesome-instagram"
      dataname="Icon awesome-instagram"
      d="M8.375,6.319a4.3,4.3,0,1,0,4.3,4.3A4.288,4.288,0,0,0,8.375,6.319Zm0,7.088a2.792,2.792,0,1,1,2.792-2.792A2.8,2.8,0,0,1,8.375,13.407Zm5.473-7.263a1,1,0,1,1-1-1A1,1,0,0,1,13.848,6.143ZM16.693,7.16a4.958,4.958,0,0,0-1.353-3.51A4.991,4.991,0,0,0,11.829,2.3c-1.383-.079-5.529-.079-6.912,0a4.983,4.983,0,0,0-3.51,1.35A4.974,4.974,0,0,0,.054,7.156c-.079,1.383-.079,5.529,0,6.912a4.958,4.958,0,0,0,1.353,3.51,5,5,0,0,0,3.51,1.353c1.383.079,5.529.079,6.912,0a4.958,4.958,0,0,0,3.51-1.353,4.991,4.991,0,0,0,1.353-3.51c.079-1.383.079-5.525,0-6.908Zm-1.787,8.392a2.827,2.827,0,0,1-1.593,1.593c-1.1.437-3.72.336-4.938.336s-3.839.1-4.938-.336a2.827,2.827,0,0,1-1.593-1.593c-.437-1.1-.336-3.72-.336-4.938s-.1-3.839.336-4.938A2.827,2.827,0,0,1,3.437,4.083c1.1-.437,3.72-.336,4.938-.336s3.839-.1,4.938.336a2.827,2.827,0,0,1,1.593,1.593c.437,1.1.336,3.72.336,4.938S15.343,14.453,14.906,15.552Z"
      transform="translate(8.875 6.574)"
      fill={schema[color] !== undefined ? schema[color][1] : schema['dark'][1]}
    />
  </svg>
)

export const LinkedIn = ({ color }) => (
  <svg
    id="share-icon-linkedin-mobile"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g
      id="Ellipse_100"
      dataname="Ellipse 100"
      fill="none"
      stroke={
        schema[color] !== undefined ? schema[color][0] : schema['dark'][0]
      }
      strokeWidth="1.5"
    >
      <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
      <circle cx="17.5" cy="17.5" r="16.75" fill="none" />
    </g>
    <path
      id="Path_366"
      dataname="Path 366"
      d="M412.364,56.633h2.817v9.815h-2.817Zm1.337-1.227h-.02a1.728,1.728,0,1,1,.02,0Zm12.748,11.043h-3.193v-5.08c0-1.329-.5-2.236-1.6-2.236a1.637,1.637,0,0,0-1.525,1.2,2.442,2.442,0,0,0-.069.8v5.311H416.9s.04-9,0-9.816h3.164v1.54a2.861,2.861,0,0,1,2.811-1.635c2,0,3.574,1.413,3.574,4.452v5.458Z"
      transform="translate(-401.604 -43.188)"
      fill={schema[color] !== undefined ? schema[color][1] : schema['dark'][1]}
      fillRule="evenodd"
    />
  </svg>
)

export const Twitter = ({ color }) => (
  <svg
    id="share-icon-twitter-mobile"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g
      id="Ellipse_99"
      dataname="Ellipse 99"
      fill="none"
      stroke={
        schema[color] !== undefined ? schema[color][0] : schema['dark'][0]
      }
      strokeWidth="1.5"
    >
      <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
      <circle cx="17.5" cy="17.5" r="16.75" fill="none" />
    </g>
    <path
      id="Path_367"
      dataname="Path 367"
      d="M125.012,56a3.166,3.166,0,0,0,1.33-1.765,5.87,5.87,0,0,1-1.922.774A2.945,2.945,0,0,0,122.212,54a3.112,3.112,0,0,0-3.025,3.192,3.33,3.33,0,0,0,.077.726,8.448,8.448,0,0,1-6.237-3.334,3.316,3.316,0,0,0-.409,1.6,3.24,3.24,0,0,0,1.346,2.657,2.915,2.915,0,0,1-1.372-.4v.039a3.153,3.153,0,0,0,2.429,3.129,2.885,2.885,0,0,1-1.368.055,3.054,3.054,0,0,0,2.827,2.217,5.877,5.877,0,0,1-3.758,1.365,5.7,5.7,0,0,1-.722-.044,8.253,8.253,0,0,0,4.638,1.434c5.567,0,8.609-4.863,8.609-9.081,0-.138,0-.277-.008-.412a6.334,6.334,0,0,0,1.51-1.653A5.807,5.807,0,0,1,125.012,56Z"
      transform="translate(-100.884 -42.479)"
      fill={schema[color] !== undefined ? schema[color][1] : schema['dark'][1]}
      fillRule="evenodd"
    />
  </svg>
)

export const SocialIcons = ({ network, color }) => {
  switch (network) {
    case 'Facebook':
      return <Facebook color={color !== undefined ? color : 'light'} />
    case 'Instagram':
      return <Instagram color={color !== undefined ? color : 'light'} />
    case 'LinkedIn':
      return <LinkedIn color={color !== undefined ? color : 'light'} />
    case 'Twitter':
      return <Twitter color={color !== undefined ? color : 'light'} />
    default:
      return <Facebook color={color !== undefined ? color : 'light'} />
  }
}

export default SocialIcons
