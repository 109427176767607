import { useStaticQuery, graphql } from 'gatsby'
import makeSlugForContentfulPost from "../../lib/makeSlugForContentfulPost";
import { EVENTS, RESOURCES } from "../../lib/collectionNames";

export const useEvents = () => {
  const data = useStaticQuery(graphql`
    query {
      allData: allResource(
        filter: { collection: { eq: "events" }, status: { eq: "Published" } }
        sort: { order: DESC, fields: data___eventDate }
      ) {
        nodes {
          id
          slug
          title
          data {
            thumbnail
            abstract
            eventDate(formatString: "MMMM D YYYY")
          }
        }
      }
      allContentfulEvent: allContentfulEvent(
        sort: { order: DESC, fields: eventDate },
        filter: { title: { ne: "Dummy" } }
      ) {
          nodes {
            id
            slug
            heading
            subHeading
            eventDate(formatString: "MMMM D YYYY")
            thumbnailImage {
              url
            }
            thumbnail{
              file{
                url
              }
            }
          }
      }
    }
  `)

  const { allData: localJSONData, allContentfulEvent: allContentfulData } = data;

  const localJSONCards = localJSONData.nodes.map(resource => ({
    id: resource.id,
    slug: `${resource.slug}/`,
    thumbnail: resource.data.thumbnail,
    publishedDate: resource.data.eventDate,
    title: resource.title,
    abstract: resource.data.abstract,
  }))

  const contentfulCards = allContentfulData.nodes.map(resource => {
    const slug = makeSlugForContentfulPost(resource, EVENTS);

    return ({
      id: resource.id,
      slug: `${slug}/`,
      thumbnail: resource.thumbnailImage ? resource.thumbnailImage[0].url : "https:" + resource.thumbnail.file.url,
      publishedDate: resource.eventDate,
      title: resource.heading,
      abstract: resource.subHeading,
    });
  })

  const allCards = [...contentfulCards, ...localJSONCards];

  return allCards
}

export default useEvents
