/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useBackgroundColors } from '../../hooks'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const Section = ({ data, depth, children }) => {
  const theme = useTheme()
  const { backgroundColor, containerStyle, additionalStyle } = data
  const backgroundSchema = useBackgroundColors(backgroundColor)

  const sharedStyles = {
    ...backgroundSchema,
    left: '-25vw',
    position: 'absolute',
    width: '150vw',
    zIndex: -1,
  }

  const pseudoElementStyles = {
    ...sharedStyles,
    borderRadius: '50%',
    content: '""',
    display: 'block',
    height: '50vw',
  }

  const ellipseStyles = {
    position: 'relative',
    // padding: depth == 0 ? theme.gutters.default : theme.gutters.yOnly,
    // top ellipse
    '&:before': {
      ...pseudoElementStyles,
      top: theme.spacing._10xl,
    },
    // bottom ellipse
    '&:after': {
      ...pseudoElementStyles,
      bottom: theme.spacing._10xl,
      ...additionalStyle,
    },
  }

  // spacer block for in between the two ellipses
  const spacerStyles = {
    ...sharedStyles,
    height: 'calc(100% - 50vw - 20rem)',
    left: '-25vw',
    top: 'calc(25vw + 10rem)',
  }

  return (
    <section style={{ ...containerStyle }}>
      <div className="ellipse" css={ellipseStyles}>
        <div css={spacerStyles} />
        <div
          css={mq({
            paddingLeft: theme.gutters.x.root,
            paddingRight: theme.gutters.x.root,
          })}
        >
          {children}
        </div>
      </div>
    </section>
  )
}

export default Section
