import { useStaticQuery, graphql } from 'gatsby'

export const useAllResources = resourceId => {
  const data = useStaticQuery(graphql`
    query {
      allData: allResource(filter: { collection: { eq: "resources" } }) {
        nodes {
          id
          slug
          publishedDate(formatString: "MMMM D YYYY")
          data {
            resourceType
            title
            thumbnail
            heading
            subHeading
            abstract
          }
        }
      }
    }
  `)

  const allTheResources = {}
  data.allData.nodes.forEach(
    resource =>
      (allTheResources[resource.id] = {
        id: resource.id,
        slug: `${resource.slug}/`,
        thumbnail: resource.data.thumbnail,
        publishedDate: resource.publishedDate,
        title: resource.data.heading,
        abstract: resource.data.abstract,
        resourceType: resource.data.resourceType,
      })
  )

  return allTheResources[resourceId]
}

export default useAllResources
