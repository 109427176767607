/**
 * useDataTransform is a hook that transforms the Data object of an Attribute
 * into Block structure and appends that to the front of the Blocks Array.
 *
 * Result is the Block Item which the caller should unshift to avoid any
 * potential mutation bugs.
 *
 * Use Cases
 *  Headings for Resource Pages
 */

import getResourceDataFromContentfulResource from "../lib/getResourceDataFromContentfulResource";

const transformBlogHeader = (resource) => {
  const isFromContentful = Boolean(resource.from);
  const { publishedDate, slug } = resource
  let data = null
  isFromContentful ? data = getResourceDataFromContentfulResource(resource) : data = resource.data
  const { author, title } = data
  const blockItem = {
    component: 'BlogHeader',
    data: {
      title: title,
      date: publishedDate,
      slug: slug,
      author: author,
      tags: [],
    },
  }
  return blockItem
}

const transformEventHeader = resource => {
  const isFromContentful = Boolean(resource.from);
  const { publishedDate } = resource
  let data = null
  isFromContentful ? data = getResourceDataFromContentfulResource(resource) : data = resource.data
  const blockItem = {
    component: 'EventHeader',
    data: {
      heading: data.heading,
      subHeading: data.subHeading,
      eventLocation: data.eventLocation,
      eventTime: data.eventTime,
      eventDate: data.eventDate,
      thumbnail: data.thumbnail,
      date: publishedDate,
    },
  }
  return blockItem
}

const transformPressNewsHeader = resource => {
  const { publishedDate } = resource
  const isFromContentful = Boolean(resource.from);
  let data = null
  isFromContentful ? data = getResourceDataFromContentfulResource(resource) : data = resource.data
  const blockItem = {
    component: 'PressNewsHeader',
    data: {
      thumbnail: data.thumbnail,
      date: publishedDate,
    },
  }
  return blockItem
}

export const useDataTransform = resource => {
  const { collection } = resource

  switch (collection) {
    case 'blogs':
    case 'blogsEngineering':
    case 'alias_blogs':
    case 'alias_blogsEngineering':
      return transformBlogHeader(resource)
    case 'events':
    case 'alias_events':
      return transformEventHeader(resource)
    case 'news':
    case 'press':
    case 'alias_news':
    case 'alias_press':
      return transformPressNewsHeader(resource)
    default:
      return
  }
}