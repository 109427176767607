const missingForwardSlash = link => {
  const paths = ['press', 'blogs', 'resources', 'news', 'events']
  for (let i = 0; i < paths.length; i++) {
    if (link.startsWith(paths[i])) return true
    else if (link.endsWith('//')) {
      paths[i].slice(0, -1);
    };
  }
  return false
}

export const useLinkEval = link => {
  if (!link) return null;

  if (
    link.startsWith('/') ||
    missingForwardSlash(link) ||
    (link.includes('accolade.com') &&
      !link.includes('member') &&
      !link.startsWith('mailto') &&
      !link.startsWith('http'))
  ) {
    return 'gatsby'
  } else if (link.includes('vimeo') || link.includes('youtu.be') || link.includes('youtube') || link.includes('.mp4')) {
    return 'video'
  } else {
    return 'classic'
  }
}

export default useLinkEval
