import { useStaticQuery, graphql } from 'gatsby'

export const useWebinars = () => {
  const data = useStaticQuery(graphql`
    query {
      allData: allResource(
        filter: { collection: { eq: "webinars" } }
        sort: { order: DESC, fields: publishedDate }
      ) {
        nodes {
          id
          slug
          publishedDate(formatString: "MMMM D YYYY")
          data {
            title
            thumbnail
            abstract
          }
        }
      }
    }
  `)

  const dataList = data.allData.nodes.map(resource => ({
    id: resource.id,
    slug: `${resource.slug}/`,
    thumbnail: resource.data.thumbnail,
    publishedDate: resource.publishedDate,
    title: resource.data.title,
    abstract: resource.data.abstract,
  }))

  return dataList
}

export default useWebinars
