export const Spacer = ({ data }) => {
  const { lineBreaks, style } = data
  return (
    <div>
      {Array(lineBreaks)
        .fill()
        .map((i, index) => (
          <br key={index} style={style} />
        ))}
    </div>
  )
}
