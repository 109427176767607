/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'
import { topNavigation, lastItemRoundButton } from '../../configs/accdotcom.navigation'
import { useEffect } from 'react'
import { TextLink } from '../RichText'
import { AccLogo } from '../AccLogos'

// /////////////////////////////////////////////////////////////////////////////
// TO DO:
//  Need to Update how images are being handled by this File.
//  Specifically <AccdotcomHeaderLogo/> needs to be determined.
//  Before, there was a Gatsby Image here along with GraphQl query
//  but we want to keep this component agnostic.
//
//  Would also like to Add context here to allow for header variation by
//  page type and/or animation of sticky and color changes.
//
//  Additionally, Recursion is not needed but would be good to normalize and
//  have a single method for unpacking Header and Footer Navs.
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////
// Presentation
// /////////////////////////////////////////////////////////////////////////////

// #region
const Box = styled.header(props =>
  mq({
    backgroundColor: props.bgColor,
    transition: 'background-color .8s ease',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: '999',
    top: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingTop: props.theme.spacing.xl,
    paddingBottom: props.theme.spacing.xl,
    paddingLeft: ['1.5rem', '3rem', '3rem', '6rem'],
    paddingRight: ['1.5rem', '3rem', '3rem', '6rem'],
    boxShadow:
      props.bgColor === '#ffffff' ? '10px 14px 35px 0px rgba(0,0,0,0.28)' : '',
  })
)

const MobileMenuBackground = styled.div(props =>
  mq({
    display: ['none', 'none', 'none', 'none !important'],
    backgroundColor: '#ffffff',
    opacity: '1',
    position: 'fixed',
    top: '0',
    right: '0',
    width: '100%',
    height: '100%',
    zIndex: '9998',
  })
)

const NavWrapper = styled.div(props =>
  mq({
    display: ['none', 'none', 'none', 'inline-block !important'],
    position: ['fixed', 'fixed', 'fixed', 'static'],
    top: '0',
    right: '0',
    paddingTop: [
      props.theme.spacing.xl,
      props.theme.spacing.xl,
      props.theme.spacing.xl,
      '0',
    ],
    width: ['100%', '20rem', '20rem', 'inherit'],
    height: ['100%', '100%', '100%', 'inherit'],
    zIndex: ['9999', '9999', '9999', 'inherit'],
    overflow: 'hidden',
    backgroundColor: props.navWrapColor,
  })
)

const Nav = styled.nav(props =>
  mq({
    listStyle: 'none',
    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'row'],
    alignItems: ['flex-start', 'flex-start', 'flex-start', 'center'],
    justifyContent: ['stretch', 'stretch', 'stretch', 'space-between'],
    marginTop: [
      props.theme.spacing._2xl,
      props.theme.spacing._2xl,
      props.theme.spacing._2xl,
      '0',
    ],
    marginLeft: [
      props.theme.spacing._4xl,
      props.theme.spacing._2xl,
      props.theme.spacing._2xl,
      '0',
    ],
  })
)

const NavLinks = styled.a(props =>
  mq({
    ...props.theme.type.scales.navMedium,
    color: props.linkColor,
    margin: [
      '0 0.5rem 0.25rem',
      '0 0.5rem 0.25rem',
      '0 0.5rem 0.25rem',
      '0 0.5rem',
      '0 1.6875rem',
    ],
    padding: '0.5rem',
    transition: 'all 0.3s ease 0s',
    '&:hover': {
      color: 'gray',
    },
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans',
  })
)

const NavOutlineLinks = styled.a(props =>
  mq({
    ...props.theme.shapes.button,
    ...props.theme.type.scales.navMedium,
    backgroundColor: props.ctaBgColor,
    color: 'white',

    margin: [
      '1rem 0 0 -0.8125rem',
      '1rem 0 0 -0.8125rem',
      '1rem 0 0 -0.8125rem',
      '0 0 0 0.5rem',
      '0 0 0 2.1875rem',
    ],
    padding: '0.75rem 1.6875rem',
    transition: 'all 0.3s ease 0s',
    '&:hover': {
      color: props.theme.colors.primary.actionBlue,
      backgroundColor: 'white',
    },
    border: '2px solid white',
    borderRadius: '1.5625rem',
    whiteSpace: 'nowrap',
  })
)

const MobileMenuButton = styled.div(props =>
  mq({
    display: ['inline-block', 'inline-block', 'inline-block', 'none'],
    margin: '7px 0 8px',
    width: '36px',
    cursor: 'pointer',
    '& div': {
      backgroundColor: '#1079BC',
      content: '""',
      display: 'block',
      height: '5px',
      margin: '10px 0',
      transition: 'all .2s ease-in-out',
    },
    // '& div:first-child': {
    //   marginTop: '0',
    // },
    // '& div:last-child': {
    //   marginBottom: '0',
    // },
  })
)

const MobileMenuCloseButton = styled.div(props =>
  mq({
    display: ['inline-block', 'inline-block', 'inline-block', 'none'],
    width: '36px',
    cursor: 'pointer',
    '& div': {
      backgroundColor: '#1079BC',
      content: '""',
      display: 'block',
      height: '5px',
      margin: '26px 0',
    },
    // '& div:first-of-type': {
    //   marginTop: '0',
    //   transform: 'translateY(15px) rotate(45deg)',
    // },
    // '& div:last-of-type': {
    //   marginBottom: '0',
    //   transform: 'translateY(-15px) rotate(-45deg)',
    // },
  })
)
//#endregion

const Banner = styled.div(props =>
  mq({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url('ipobanner.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  })
)

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const GlobalHeader = props => {
  const theme = useTheme()
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const options = Object.values(topNavigation)

  const lastItem = lastItemRoundButton.map((a, index) => {
    const yy = window.location.toString().includes('industry/government') ? a["industry/government"] : a.default
    return (
      <NavOutlineLinks
        key={index}
        href={yy.link}
        ctaBgColor={
          isMobileOpen
            ? theme.colors.primary.actionBlue
            : props.bgSchema.ctaBgColor
        }
      >
        {yy.label}
      </NavOutlineLinks>
    )
  })

  const items = options.map((item, index) => {

    return (
      <NavLinks
        key={index}
        css={{}}
        href={item.link}
        linkColor={isMobileOpen ? 'black' : props.bgSchema.color}
      >
        {item.label}
      </NavLinks>)

  })

  const handleMobileButtonClick = event => {
    setIsMobileOpen(true)
  }

  const handleMobileCloseButtonClick = event => {
    setIsMobileOpen(false)
  }
  // console.log('HEADER => BG SCHEMA')
  // console.log(props)

  return (
    <>
      <Box bgColor={props.bgSchema.backgroundColor}>
        <div>
          <TextLink link="/">
            <AccLogo style={props.bgSchema.accLogo} />
          </TextLink>
        </div>
        <NavWrapper
          navWrapColor={props.backgroundColor}
          css={{
            display: isMobileOpen ? 'block !important' : 'none !important',
          }}
        >
          <div css={{ textAlign: 'right', padding: '0 1.6rem 0 0' }}>
            <MobileMenuCloseButton onClick={handleMobileCloseButtonClick}>
              <div></div>
              <div></div>
            </MobileMenuCloseButton>
          </div>
          <Nav>{items}<>{lastItem}</></Nav>
        </NavWrapper>
        <MobileMenuBackground
          css={{
            display: isMobileOpen ? 'block !important' : 'none !important',
          }}
          onClick={() => setIsMobileOpen(false)}
        />
        <MobileMenuButton onClick={handleMobileButtonClick}>
          <div></div>
          <div></div>
          <div></div>
        </MobileMenuButton>
      </Box>
    </>
  )
}

export default GlobalHeader
