/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../../hooks'

const theme = {
  start: {
    default: '#1079BC',
    hover: 'White'
  },
  pause: {
    default: 'gray',
    hover: 'White'
  }
}
const Button = styled.button`
&:hover {
  border-color: ${props => theme[props.theme].default};
  background-color: ${props => theme[props.theme].hover};
  color: ${props => theme[props.theme].default};
}
float:left;
margin: 25px 50px 75px 25px;
border-color: ${props => theme[props.theme].default};
background-color: ${props => theme[props.theme].default};
color: ${props => theme[props.theme].hover};
border-radius: 2.5rem;
border-width: 2px;
border-style: solid;
padding: 1rem 2rem;
max-width: 100%;
width: auto;
text-align: center;
`
Button.defaultProps = {
  theme: 'start'
}

export const PodcastButton = props => {
  return(
    <div>
      <Button theme={props.type} onClick={props.clicked} style={props.style}>{props.text}</Button>
    </div>
  )
}

export default PodcastButton