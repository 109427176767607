import React from 'react'
import escapeHtml from 'escape-html'
import { v4 as uuidv4 } from 'uuid'
import { Image } from '../Image'

import {
  Display,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  TitleXs,
  TitleXxs,
  Body,
  BodySmall,
  BlueText,
  FootNote,
  Caption,
  ButtonLink,
  ButtonTransparent,
  OrderedList,
  UnorderedList,
  OrderedListItem,
  UnorderedListItem,
  Bold,
  Italic,
  Underline,
  SuperScript,
  Attribution,
  Quote,
  TextLink,
} from './RichTextStyles'
import theme from '../../configs/accdotcom.theme'
import { mq } from '../../hooks'
import { AccForm } from "../../forms/AccForm";

// /////////////////////////////////////////////////////////////////////////////
// Config:
// /////////////////////////////////////////////////////////////////////////////

const componentMap = (componentName, componentData, children) => {
  const index = uuidv4()
  const componentNameLower = componentName.toLowerCase()
  const textStyle = componentData.hasOwnProperty('textStyle') ? componentData.textStyle : {}

  const mapConfig = {
    AccForm: {
      variants: ['AccForm', 'Form'],
      return: componentData.data?.containerStyle ? (
        <div key={index} css={{ ...componentData.data?.containerStyle }}>
          <div css={{ display: 'flex', justifyContent: componentData.data?.alignment, ...componentData.data?.containerStyle }}>
            <AccForm key={index} formtype={'resourceForm'} thankyou={componentData.data?.thankyou} onSubmitSuccess={componentData.data?.onSubmitSuccess} SubmitText={componentData.data?.SubmitText} />
          </div>
        </div>) : (
        <div key={index} css={{ display: 'flex', justifyContent: componentData.data?.alignment, ...componentData.data?.containerStyle }}>
          <AccForm key={index} formtype={'resourceForm'} thankyou={componentData.data?.thankyou} onSubmitSuccess={componentData.data?.onSubmitSuccess} SubmitText={componentData.data?.SubmitText} />
        </div>)
    },
    Display: {
      variants: ['Display', 'H1', 'heading-1'],
      return: (
        <Display key={index} textStyle={textStyle}>
          {children}
        </Display>
      ),
    },
    TitleLarge: {
      variants: ['TitleLarge', 'H2', 'heading-2'],
      return: (
        <TitleLarge key={index} textStyle={textStyle}>
          {children}
        </TitleLarge>
      ),
    },
    TitleMedium: {
      variants: ['TitleMedium', 'H3', 'heading-3'],
      return: (
        <TitleMedium key={index} textStyle={textStyle}>
          {children}
        </TitleMedium>
      ),
    },
    TitleSmall: {
      variants: ['TitleSmall', 'H4', 'heading-4'],
      return: (
        <TitleSmall key={index} textStyle={textStyle}>
          {children}
        </TitleSmall>
      ),
    },
    TitleXs: {
      variants: ['TitleXs', 'H5', 'heading-5'],
      return: (
        <TitleXs key={index} textStyle={textStyle}>
          {children}
        </TitleXs>
      ),
    },
    TitleXxs: {
      variants: ['TitleXxs'],
      return: (
        <TitleXxs key={index} textStyle={textStyle}>
          {children}
        </TitleXxs>
      ),
    },
    Body: {
      variants: ['Body', 'paragraph', 'Paragraph', 'P'],
      return: (
        <Body key={index} textStyle={textStyle}>
          {children}
        </Body>
      ),
    },
    BodySmall: {
      variants: ['BodySmall'],
      return: (
        <BodySmall key={index} textStyle={textStyle}>
          {children}
        </BodySmall>
      ),
    },
    Caption: {
      variants: ['Caption'],
      return: (
        <Caption key={index} textStyle={textStyle}>
          {children}
        </Caption>
      ),
    },
    FootNote: {
      variants: ['FootNote', 'Footer', 'source'],
      return: (
        <FootNote key={index} textStyle={textStyle}>
          {children}
        </FootNote>
      ),
    },
    ButtonLink: {
      variants: ['ButtonLink', 'ButtonText', 'ButtonCta', 'ContentfulCtaButton'],
      return: (
        <ButtonLink
          key={index}
          link={escapeHtml(`${componentData.url}`)}
          textStyle={textStyle}
          newTab={componentData.newTab}
        >
          {children}
        </ButtonLink>
      ),
    },
    ButtonTransparent: {
      variants: ['ButtonTransparent'],
      return: (
        <ButtonTransparent
          key={index}
          link={escapeHtml(`${componentData.url}`)}
          textStyle={textStyle}
        >
          {children}
        </ButtonTransparent>
      ),
    },
    OrderedList: {
      variants: ['OrderedList', 'OL', 'ol-list', 'ordered-list'],
      return: (
        <OrderedList key={index} textStyle={textStyle}>
          {children}
        </OrderedList>
      ),
    },
    UnorderedList: {
      variants: ['UnorderedList', 'UL', 'ul-list', 'Unordered-list'],
      return: (
        <UnorderedList key={index} textStyle={textStyle}>
          {children}
        </UnorderedList>
      ),
    },
    OrderedListItem: {
      variants: ['OrderedListItem', 'ol-list-item', 'list-item'],
      return: (
        <OrderedListItem key={index} textStyle={textStyle}>
          {children}
        </OrderedListItem>
      ),
    },
    UnorderedListItem: {
      variants: [
        'UnorderedListItem',
        'ul-list-item',
        'ListItemUnOrdered',
        'list-item',
      ],
      return: (
        <UnorderedListItem key={index} textStyle={textStyle}>
          {children}
        </UnorderedListItem>
      ),
    },
    BlueText: {
      variants: ['BlueText'],
      return: (
        <BlueText key={index} textStyle={textStyle}>
          {children}
        </BlueText>
      ),
    },
    Bold: {
      variants: ['Bold'],
      return: (
        <Bold key={index} textStyle={textStyle}>
          {children}
        </Bold>
      ),
    },
    Italic: {
      variants: ['Italic'],
      return: (
        <Italic key={index} textStyle={textStyle}>
          {children}
        </Italic>
      ),
    },
    Underline: {
      variants: ['Underline'],
      return: (
        <Underline key={index} textStyle={textStyle}>
          {children}
        </Underline>
      ),
    },
    SuperScript: {
      variants: ['SuperScript'],
      return: (
        <SuperScript key={index} textStyle={textStyle}>
          {children}
        </SuperScript>
      ),
    },
    Attribution: {
      variants: ['Attribution'],
      return: (
        <Attribution key={index} textStyle={textStyle}>
          {children}
        </Attribution>
      ),
    },
    Quote: {
      variants: ['Quote'],
      return: (
        <Quote key={index} textStyle={textStyle}>
          {children}
        </Quote>
      ),
    },
    TextLink: {
      variants: ['AccLink', 'TextLink', 'Link', 'hyperlink'],
      return: (
        <TextLink
          key={index}
          link={escapeHtml(`${componentData.url}`)}
          textStyle={textStyle}
        >
          {children}
        </TextLink>
      ),
    },
    Image: {
      variants: ['Image', 'image', 'embedded-asset-block'],
      return: (
        <div key={index} css={mq({ display: 'flex', ...textStyle })}>
          <Image
            data={{
              path: componentData.url ? componentData.url : componentData.src,
              imgStyle: componentData.imgStyle ? componentData.imgStyle : {},
              containerStyle: componentData.containerStyle ? componentData.containerStyle : {},
              linkUrl: componentData.linkUrl
            }}
          />
        </div>
      ),
    },
    Container: {
      variants: ['Container'],
      return: (
        <div key={index} css={mq({ ...textStyle })}>
          {children}
        </div>
      ),
    },
    Fragment: {
      variants: ['Fragment'],
      return: (
        <>
          {children}
        </>
      ),
    },
  }

  let mapFinal = {}
  Object.keys(mapConfig).forEach((key) => {
    mapConfig[key].variants.forEach((variant) => {
      mapFinal[variant.toLowerCase()] = mapConfig[key].return
    })
  })

  return mapFinal[componentNameLower] ? mapFinal[componentNameLower] : <></>
}

// /////////////////////////////////////////////////////////////////////////////
// Recursive Flow:
// /////////////////////////////////////////////////////////////////////////////

const serializeContent = (node, depth) => {
  const inlineKeys = ['bold', 'italic', 'underline', 'superscript', 'bluetext']
  const inlineStyles = Object.keys(node).filter((key) =>
    inlineKeys.includes(key)
  )
  const isTextNode =
    node.hasOwnProperty('text') && typeof node.text === 'string'

  const hasInlineStyles = inlineStyles.length > 0
  const hasChildren = node.hasOwnProperty('children')

  if (isTextNode && hasInlineStyles) {
    let styledText
    inlineStyles.forEach((style) => {
      styledText = styledText
        ? (styledText = componentMap(style, {}, [styledText]))
        : componentMap(style, {}, [node.text])
    })
    return styledText
  }

  if (isTextNode) {
    return node.text
  }

  const componentName = node.type
  const nodeData = Object.entries(node).reduce((nodeData, [key, val]) => {
    if (!['type', 'text', 'children'].includes(key)) {
      nodeData[key] = val
    }
    return nodeData
  }, {})

  let children = hasChildren
    ? node.children.map((node) => serializeContent(node, depth + 1))
    : []

  let childrenWithSpacing = children.reduce((childrenWithSpacing, item) => {
    childrenWithSpacing.push(item)
    childrenWithSpacing.push(' ')
    return childrenWithSpacing
  }, [])
  childrenWithSpacing.splice(-1, 1)

  const result = componentMap(componentName, nodeData, childrenWithSpacing)

  return result
}

// /////////////////////////////////////////////////////////////////////////////
// Main Component:
// /////////////////////////////////////////////////////////////////////////////

export const RichText = ({ data }) => {
  const { anchorId, content: contentBlock = [], containerStyle } = data

  try {
    const validNodes = contentBlock.filter((node) => node.type !== undefined)
    const elements = validNodes.map((node) => serializeContent(node, 0))
    // Add 6xl top spacing for html anchor links to account for nav height
    // only if it has an anchorId
    const styles = {
      ...(anchorId && { paddingTop: theme.spacing._6xl }),
      ...containerStyle,
    }
    return (
      <div id={anchorId} css={styles}>
        {elements}
      </div>
    )
  } catch (error) {
    let errorObj = {
      module: 'Rich Text',
      type: 'Unhandled Error',
      error: error,
      message: 'default branch executed',
      contentBlock: contentBlock,
    }

    return <></>
  }
}

// Critical: provides consistency cross dev and prod builds
RichText.displayName = 'richtext'
