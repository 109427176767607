import styled from '@emotion/styled'

export const Select = styled.select`
  display: block;
  padding: 0.6em 1.4em 0.5em 0.8em;
  height: 49px;
  margin: 0;
  border: ${props => (props.valid ? '1px solid red' : '1px solid #ccc')};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`

export const Input = styled.input`
  height: 49px;
  padding: 0.6em 1.4em 0.5em 0.8em;
  border: ${props => (props.valid ? '1px solid red' : '1px solid #ccc')};
  background-color: #fff;
`
export const Textarea = styled.textarea`
  height: 200px;
  padding: 0.6em 1.4em 0.5em 0.8em;
  border: 1px solid #ccc;
  background-color: #fff;
`
export const DivRequired = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 0.25rem;
`
export const Button = styled.button`
  min-width: 190px;
  height: 50px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  background-color: #1079bc;
  border-radius: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  font-family: "Open Sans",sans-serif;
  -webkit-appearance: none;
`
