import React from 'react'
import Modal from 'react-responsive-modal'
import ReactPlayer from 'react-player'
import { mq } from '../../hooks'
export default class ModalVideo extends React.Component {
  state = {
    open: false,
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }
  onCloseModal = () => {
    this.setState({ open: false })
  }

  getWidth() {
    if (typeof window !== `undefined`)
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )
    else return 799
  }

  render() {
    const { open } = this.state
    const width = this.getWidth()
    const modalStyles = {
      overlay: { background: 'rgba(0, 61, 166, 0.8)' },
      modal: {
        background: 'none',
        boxShadow: 'none',
      },
    }

    return (
      <>
        <div
          onClick={this.onOpenModal}
          style={{ cursor: 'pointer', ...this.props.textStyle }}
        >
          {this.props.children}
        </div>

        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          styles={modalStyles}
          closeIconSize={0}
        >
          {width < 800 ? (
            <ReactPlayer
              width={{}}
              height={{}}
              url={this.props.link}
              controls={true}
            />
          ) : (
            <ReactPlayer url={this.props.link} controls={true} />
          )}
        </Modal>
      </>
    )
  }
}
