export const useDateFormatter = dateObject => {
  if (!dateObject) return null;

  //  HOTFIX
  //  if contains space, then the date came from Contentful
  if (dateObject.includes(" ")) return dateObject;

  const technicalDate = new Date(dateObject);
  const displayDate = technicalDate.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  return displayDate;
}

export default useDateFormatter
