/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

import { bottomNavigation } from '../../configs/accdotcom.navigation'
import { footerNavigation } from '../../configs/accdotcom.navigation'
import { socialNavigation } from '../../configs/accdotcom.navigation'
import { SocialIcons } from '../SocialIcons'
import { AccLogo } from '../AccLogos'
import { TextLink } from '../RichText'

// ////////////////////////////////////////////////////////////////////////////
// To DO:
//  Audit React Keys. Aggressively added keys everywhere due to error.
// ////////////////////////////////////////////////////////////////////////////

// ////////////////////////////////////////////////////////////////////////////
// Utility Components - Bottom Nav
// ////////////////////////////////////////////////////////////////////////////
const BottomNav = styled.nav(props =>
  mq({
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    flex: 1,
    paddingBottom: [0, 0, '2rem'],
    justifyContent: 'flex-end',
  })
)
const BottomNavGroup = styled.ul(props =>
  mq({
    paddingTop: [0, '3rem', 0],
    paddingLeft: [0, 0, '3rem'],
  })
)

const BottomNavSub = styled.ul(props =>
  mq({ display: 'block', marginLeft: ['1rem', '1rem', '0'] })
)

const BottomNavItem = ({ index, level, label, link, type }) => {
  const theme = useTheme()

  const textScale =
    level === 0 ? theme.type.scales.titleXs : theme.type.scales.bodySmall

  const liPadding =
    level === 0
      ? { paddingBottom: ['1rem', theme.spacing.xl] }
      : { paddingBottom: theme.spacing.xs }

  return (
    <li
      key={{ index }.toString().concat('ni')}
      css={mq({
        ...textScale,
        ...liPadding,
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
      })}
    >
      <TextLink link={link}>{label}</TextLink>
    </li>
  )
}

const RecursiveBottomNav = (data, level, index) => {
  const itemData = {
    label: data.label,
    link: data.link,
    type: data.type,
    level: level,
    key: index,
  }

  const subItems = data.items.map((data, subIndex) =>
    RecursiveBottomNav(data, level + 1, index.concat('', level + 1, subIndex))
  )

  if (level === 0) {
    return (
      <BottomNavGroup key={index.concat('outerList')}>
        <BottomNavItem key={index.concat('innerItem')} {...itemData} />
        <BottomNavSub key={index.concat('innerList')}>{subItems}</BottomNavSub>
      </BottomNavGroup>
    )
  } else {
    return <BottomNavItem key={index.concat('base')} {...itemData} />
  }
}

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const GlobalFooter = ({ props }) => {
  const theme = useTheme()

  const bottomNavItems = bottomNavigation.map((data, index) =>
    RecursiveBottomNav(data, 0, index.toString())
  )
  return (
    <footer
      css={mq({
        backgroundColor: theme.colors.primary.blue,
        justifySelf: 'end',
        color: '#fff',
        marginTop: ['0', '0', '0'],
        paddingLeft: ['2rem', '3rem', '4rem'],
        paddingRight: ['2rem', '3rem', '4rem'],
        paddingTop: ['2rem', '1rem', '3rem'],
        paddingBottom: ['1rem', '1rem', '2rem'],
      })}
    >
      <BottomNav key="fbn" css={{ justifyContent: 'space-between' }}>
        <div css={{ justifySelf: 'stretch', color: '#ffffff' }}>
          <TextLink link="/">
            <AccLogo style="greenWhite" />
          </TextLink>
        </div>
        {bottomNavItems}
      </BottomNav>
      <nav
        key="0nav"
        css={mq({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: ['column', 'row'],
        })}
      >
        <nav
          key="1nav"
          css={mq({
            display: 'flex',
            paddingTop: '2rem',
            flexGrow: 1,
            alignItems: 'center',
          })}
        >
          {socialNavigation.map((o, index) => (
            <div
              key={'innersocial'.concat(index)}
              css={{ marginRight: '0.75rem' }}
            >
              <a
                href={o.link}
                target="_blank"
                css={mq({
                  display: 'block',
                  height: '1.6em',
                  width: '1.6em',
                  padding: '.3rem',
                  margin: '.1rem',
                  textDecoration: 'none',
                  marginRight: '0.5rem',
                  textAlign: 'center',
                  lineHeight: 1.5,
                })}
              >
                <SocialIcons
                  key={index.toString().concat('fsi')}
                  network={o.label}
                  color="light"
                />
              </a>
            </div>
          ))}
        </nav>
        <ul
          css={mq({
            ...theme.type.scales.caption,
            display: 'flex',
            paddingTop: '2rem',
            flexDirection: ['column-reverse', 'row'],
            listStyleType: 'none',
          })}
        >
          <li
            css={mq({
              padding: ['0.5rem 0', '0 1rem 0 0', '0 1.8rem 0 0'],
              display: 'flex',
              alignItems: 'center',
              textAlign: 'right',
            })}
          >
            <span css={mq({ whiteSpace: 'nowrap' })}>
              © {new Date().getFullYear()} Accolade.
            </span>
            <wbr></wbr>
            <span css={mq({ whiteSpace: 'nowrap' })}>
              &nbsp;All rights reserved.
            </span>
          </li>
          {footerNavigation.map((o, index) => (
            <li
              key={'fni'.concat(index)}
              css={mq({
                padding: ['0.5rem 0', '0 1rem', '0 1.8rem'],
                borderLeft: [0, '1px solid rgb(120, 120, 120)'],
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
              })}
            >
              <TextLink key={index.toString().concat('fl')} link={o.link}>
                {o.label}
              </TextLink>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  )
}

export default GlobalFooter
