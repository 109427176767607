/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'
import { ButtonLink } from '../RichText'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const CallToAction = ({ data }) => {
  const { target, text } = data
  const theme = useTheme()

  return (
    <ButtonLink css={{ padding: '2rem' }} link={target}>
      {text}
    </ButtonLink>
  )
}

export default CallToAction
