/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'
import { Display } from '../RichText'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

const Header = styled.div(props => ({
  width: '100%',
  backgroundColor: `${props.theme.colors.products[props.color]}`,
  minHeight: '160px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const InnerText = styled.h2(props => ({
  color: 'white',
  fontSize: '2.5rem',
  lineHeight: '3.25rem',
  textAlign: 'center',
}))

const getBoxPadding = boxType => {
  if (boxType === 'Standard') return '3rem'
  if (boxType === 'NoPadding') return ['0', '0', '3rem', '3rem']
  return '0'
}

export const RaisedBox = ({ data, children }) => {
  const theme = useTheme()
  return (
    <div
      css={mq({
        padding: getBoxPadding(data.type),
        // padding: data.type === 'Standard' ? '3rem' : '0',
        backgroundColor: '#ffffff',
        filter: 'drop-shadow(0 0 20px #707070)',
      })}
    >
      {data.type !== 'Standard' && data.type !== 'NoPadding' ? (
        <Header color={data.color}>
          <InnerText>{data.header}</InnerText>
        </Header>
      ) : null}
      {children}
    </div>
  )
}

export default RaisedBox
