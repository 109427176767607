import { normalizeFormConfigName } from "../lib/normalizeFormConfigName";

const states = [
  'Please select',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]
const employeeBand = [
  'Please select',
  '< 200',
  '200 - 499',
  '500 - 4,999',
  '5,000 - 34,999',
  '> 35,000',
]
const departments = [
  'Please select',
  'Account Management',
  'CEO',
  'Chief Legal',
  'Chief Medical Officer',
  'Consultant',
  'Controller',
  'COO',
  'Finance - CFO',
  'Finance - Other',
  'HR - Analyst',
  'HR - Benefits/Total Rewards',
  'HR - CHRO',
  'HR - Other',
  'HR - Wellness',
  'IT',
  'Medical',
  'Procurement',
  'Risk',
  'Sales & MKT',
  'Others',
]
const solutionCategories = [
  'Please select',
  '2nd Opinion',
  'Behavioral Health',
  'Benefit Administration',
  'Centers of Excellence',
  'Diabetes •Digestive Health',
  'Diagnostic Services',
  'EAP',
  'Financial Wellness',
  'General Wellness',
  'Musculoskeletal',
  'Pharmacy',
  'Primary Care',
  'Specialty Disease Management',
  'Telemedicine•Women’s Health',
  'Other',
]

function extrapolateDemoDates(dayOfWeek, hour, numWeeks) {
  const today = new Date()
  const currDay = today.getDay()
  const currHour = today.getHours()

  let baseline = null
  if (currDay < dayOfWeek) {
    baseline = new Date(today.setDate(today.getDate() + dayOfWeek - currDay))
  } else if (currDay > dayOfWeek) {
    const daydiff = dayOfWeek - currDay
    baseline = new Date(today.setDate(today.getDate() + (7 + daydiff)))
  } else if (currDay === dayOfWeek) {
    if (currHour < hour) baseline = new Date(today.setDate(today.getDate()))
    else baseline = new Date(today.setDate(today.getDate() + 7))
  }

  const demoDate = new Date(
    baseline.getFullYear(),
    baseline.getMonth(),
    baseline.getDate(),
    hour
  )

  let datesToReturn = ['Please select']
  datesToReturn.push(demoDate.toString())
  for (let i = 1; i < numWeeks; i++) {
    let demoDateClone = new Date(demoDate)
    let adjustedDate = new Date(
      demoDateClone.setDate(demoDateClone.getDate() + i * 7)
    )
    datesToReturn.push(adjustedDate.toString())
  }
  return datesToReturn
}
const totalCareDates = extrapolateDemoDates(2, 11, 4)
const totalBenefitsDates = extrapolateDemoDates(4, 11, 4)
const covidResponseCareDates = extrapolateDemoDates(3, 11, 4)

const ContactUs = [
  {
    richText: {
      data: {
        color: 'blue',
        content: [
          {
            type: 'TitleLarge',
            textStyle: {
              textAlign: 'center',
            },
            children: [
              {
                text: 'Contact us',
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'comments',
        label: 'Comments',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const ContactCovid = [
  {
    richText: {
      data: {
        color: 'blue',
        content: [
          {
            type: 'TitleLarge',
            textStyle: {
              textAlign: 'center',
            },
            children: [
              {
                text: 'Register Now',
              },
            ],
          },
        ],
      },
    },

    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const ContactBenefitsAdvisors = [
  {
    richText: {
      data: {
        color: 'blue',
        content: [
          {
            type: 'TitleLarge',
            textStyle: {
              textAlign: 'center',
            },
            children: [
              {
                text: 'Discover what Accolade can do for your clients.',
              },
              {
                text:
                  'Contact us to learn more and schedule a demo with Accolade today.',
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'comments',
        label: 'Comments',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const MentalHealth = [
  {
    richText: {
      data: {
        style: {
          boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
        },
        color: 'blue',
        content: [
          {
            type: 'TitleMedium',
            textStyle: {
              textAlign: 'center',
              paddingTop: '250px',
            },
            children: [
              {
                text:
                  'Discover what Accolade can do for your employee population.',
                textStyle: { width: '100%' },
              },
              {
                type: 'p',
              },
              {
                text: 'Contact us to learn more',
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'comments',
        label: 'Comments',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const DemoTotalBenefits = [
  {
    richText: {
      data: {
        content: [
          {
            type: 'TitleLarge',
            children: [
              {
                text: 'Register to view the 20 minute group demo',
              },
            ],
          },
        ],
      },
    },
    fields: [
      {
        name: 'webinarDates',
        label: 'Select Date & Time',
        type: 'oneOf',
        value: totalBenefitsDates[0],
        options: totalBenefitsDates,
        required: true,
        span: 2,
      },
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'jane@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Acme',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneof',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneof',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const DemoCovidResponseCare = [
  {
    richText: {
      data: {
        content: [
          {
            type: 'titleXs',
            children: [
              {
                text:
                  'The Accolade COVID Response Care demo provides a view into:',
              },
            ],
          },
          {
            type: 'ul-list',
            children: [
              {
                type: 'ul-list-item',
                children: [
                  {
                    text: 'Clinical protocols and guidelines.',
                    bold: true,
                  },
                  {
                    text:
                      'Get the right support for your business continuity plan.',
                  },
                ],
              },
              {
                type: 'ul-list-item',
                children: [
                  {
                    text: 'Real people.',
                    bold: true,
                  },
                  {
                    text:
                      'Our registered nurses and Accolade Health Assistants answer every message or call.',
                  },
                ],
              },
              {
                type: 'ul-list-item',
                children: [
                  {
                    text: 'Technology.',
                    bold: true,
                  },
                  {
                    text:
                      'Our mobile app, messaging, and online portal provide easy access for employees.',
                  },
                ],
              },
            ],
          },
          {
            type: 'TitleLarge',
            children: [
              {
                text: 'Register to view the 20 minute group demo',
              },
            ],
          },
        ],
      },
    },
    fields: [
      {
        name: 'webinarDates',
        label: 'Select Date & Time',
        type: 'oneOf',
        value: covidResponseCareDates[0],
        options: covidResponseCareDates,
        required: true,
        span: 2,
      },
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'jane@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Acme',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneof',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneof',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const DemoTotalCare = [
  {
    richText: {
      data: {
        content: [
          {
            type: 'titleXs',
            children: [
              {
                text: 'Total Care Provides:',
              },
            ],
          },
          {
            type: 'ul-list',
            children: [
              {
                type: 'ul-list-item',
                children: [
                  {
                    text:
                      'The first step to helping your people get the quality care they deserve',
                  },
                ],
              },
              {
                type: 'ul-list-item',
                children: [
                  {
                    text:
                      'Ongoing clinical support to improve confidence and compliance',
                  },
                ],
              },
              {
                type: 'ul-list-item',
                children: [
                  {
                    text:
                      'Healthcare by helping your employees receive the best, most relevant care',
                  },
                ],
              },
            ],
          },
          {
            type: 'TitleLarge',
            children: [
              {
                text: 'Register to view the 20 minute group demo',
              },
            ],
          },
        ],
      },
    },
    fields: [
      {
        name: 'webinarDates',
        label: 'Select Date & Time',
        type: 'oneOf',
        value: totalCareDates[0],
        options: totalCareDates,
        required: true,
        span: 2,
      },
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'jane@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Acme',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneof',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneof',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const DemoCustom = [
  {
    richText: {
      data: {
        content: [
          {
            type: 'TitleLarge',
            children: [
              {
                text: 'Register now',
              },
            ],
          },
        ],
      },
    },
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'jane@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '555-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Acme',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberOfUSEmployees',
        label: 'Number of US employees',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const govForm = [
  {
    richText: {
      data: {
        style: {
          boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
        },
        color: 'blue',
        content: [
          {
            type: 'h2',
            textStyle: {
              textAlign: 'center',
              paddingTop: '250px',
            },
            children: [
              {
                text:
                  'Contact us today to learn more about how Accolade can support your needs.',
                textStyle: { width: '100%' },
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'agencyName',
        label: 'Agency name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'numberBeneficiaries',
        label: 'Number of beneficiaries ',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'comments',
        label: 'Comments',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const aonForm = [
  {
    richText: {
      data: {
        style: {
          boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
        },
        color: 'blue',
        content: [
          {
            type: 'h2',
            textStyle: {
              textAlign: 'center',
              paddingTop: '20px',
            },
            children: [
              {
                text:
                  'Get the report',
                textStyle: { width: '100%' },
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberemployees',
        label: 'Number of employees ',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const emoForm = [
  {
    richText: {
      data: {
        style: {
          boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
        },
        color: 'blue',
        content: [
          {
            type: 'h2',
            textStyle: {
              textAlign: 'center',
              paddingTop: '20px',
            },
            children: [
              {
                text:
                  'Talk to Accolade today',
                textStyle: { width: '100%' },
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberemployees',
        label: 'Number of employees ',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const PartnerReferral = [
  {
    richText: {
      data: {
        content: [
          {
            type: 'TitleLarge',
            textStyle: {
              textAlign: 'center',
            },
            children: [
              {
                text: 'Employer Information',
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thank you for your interest in partnering with Accolade and submitting this information.',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "Our team with review the submission and based on our current priorities, will reach out to line up an initial discussion.",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'contactEmail',
        label: 'Contact Email',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyName',
        label: 'Company Name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'headquartersAddress',
        label: 'Headquarters Address',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'categoryOfSolution',
        label: 'Category of Solution',
        type: 'oneOf',
        value: 'Please select',
        options: solutionCategories,
        required: true,
      },
      {
        name: 'solutionOverview',
        label: 'Solution Overview',
        subtext: '',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'goalOfPartnership',
        label: 'Goal Of Partnership',
        subtext: '',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'additionalInfo',
        label: 'Additional Information',
        subtext:
          '(Comment that helps to better understand the value to Accolade)',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: '',
        span: 2,
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message: "By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.",
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

const resourceForm = [
  {
    richText: {
      data: {
        style: {
          boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
        },
        color: 'blue',
        content: [
          {
            type: 'h2',
            textStyle: {
              textAlign: 'center',
              paddingTop: '20px',
            },
            children: [
              {
                text:
                  'Get the resource',
                textStyle: { width: '100%' },
              },
            ],
          },
        ],
      },
    },
    thankYou: [
      {
        type: 'TitleLarge',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: 'Thanks for your message!',
          },
        ],
      },
      {
        type: 'Body',
        textStyle: { textAlign: 'center' },
        children: [
          {
            text: "We'll be in touch soon",
          },
        ],
      },
    ],
    fields: [
      {
        name: 'email',
        label: 'Work email',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'janedoe@email.com',
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Jane',
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'input',
        value: '',
        required: true,
        placeholder: 'Doe',
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        type: 'input',
        value: '',
        required: true,
        placeholder: '(555)-555-5555',
      },
      {
        name: 'companyName',
        label: 'Company name',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'companyState',
        label: 'Company state',
        type: 'oneOf',
        value: 'Please select',
        options: states,
        required: true,
      },
      {
        name: 'numberemployees',
        label: 'Number of employees ',
        type: 'oneOf',
        value: 'Please select',
        options: employeeBand,
        required: true,
      },
      {
        name: 'job_title',
        label: 'Job title',
        type: 'input',
        value: '',
        required: true,
        placeholder: '',
      },
      {
        name: 'privacyPolicy',
        label: 'Accept privacy policy',
        type: 'checkbox',
        message:
          'By submitting this form, I agree to receive periodic emails from Accolade related to products and services and can unsubscribe at any time. I accept the Accolade Privacy Policy.',
        value: false,
        required: true,
        span: 1,
      },
    ],
  },
]

export const AccForms = {
  contactus: ContactUs,
  contactcovid: ContactCovid,
  contactbenefitsadvisors: ContactBenefitsAdvisors,
  custom: DemoCustom,
  mentalHealth: MentalHealth,
  govForm: govForm,
  aonForm: aonForm,
  emoForm: emoForm,
  referral: PartnerReferral,
  resourceForm: resourceForm,
}

export const AccDemoForms = {
  totalbenefits: DemoTotalBenefits,
  totalcare: DemoTotalCare,
  demoCovidResponseCare: DemoCovidResponseCare,
}

export const AllForms = Object.fromEntries(Object.entries({ ...AccForms, ...AccDemoForms }).map(([key, value]) => [normalizeFormConfigName(key), value]));
