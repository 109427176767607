/** @jsx jsx */
import React, { Children } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const QuoteGrid = ({ data, children }) => {
  const theme = useTheme()
  const { containerStyle } = data
  const childCount = Children.count(children)
  const gridPercent = Math.floor(100 / childCount) - 2

  return (
    <section
      css={mq({
        display: 'flex',
        flexWrap: 'no-wrap',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 'auto',
        width: '100%',
        ...containerStyle,
      })}
    >
      <div
        key={`grid-item-0`}
        css={mq({
          flex: '3',
          margin: '1em 0',
        })}
      >
        {children[0]}
      </div>
      <div
        key={`grid-item-1`}
        css={mq({
          flex: '1',
          margin: '1em 0',
          display: ['none', 'none', 'block', 'block'],
        })}
      >
        {children[1]}
      </div>
      <div
        key={`grid-item-2`}
        css={mq({
          flex: '1',
          margin: '1em 0',
        })}
      >
        {children[2]}
      </div>
    </section>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// Samples
// /////////////////////////////////////////////////////////////////////////////

export default QuoteGrid
