/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'
import { Image } from '../Image'
import { RichText } from '../RichText'

// /////////////////////////////////////////////////////////////////////////////
// Static
// /////////////////////////////////////////////////////////////////////////////

const svgPlus = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g
      id="Icon_feather-plus"
      data-name="Icon feather-plus"
      transform="translate(-6 -6)"
    >
      <path
        id="Path_505"
        data-name="Path 505"
        d="M18,7.5v25"
        transform="translate(2 0)"
        fill="none"
        stroke="#1079bc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_506"
        data-name="Path 506"
        d="M7.5,18h25"
        transform="translate(0 2)"
        fill="none"
        stroke="#1079bc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
)
const svgMinus = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="3"
    viewBox="0 0 28 3"
  >
    <g
      id="Icon_feather-plus"
      data-name="Icon feather-plus"
      transform="translate(-6 -18.5)"
    >
      <path
        id="Path_506"
        data-name="Path 506"
        d="M7.5,18h25"
        transform="translate(0 2)"
        fill="none"
        stroke="#1079bc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
)

// /////////////////////////////////////////////////////////////////////////////
// Parts
// /////////////////////////////////////////////////////////////////////////////

const AccordionContainer = styled.article(props => ({
  marginTop: '2rem',
  borderBottom: '1px solid',
  display: 'flex',
  justifyContent: 'flex-start',
  flexFlow: 'row wrap',
  alignItems: 'stretch',
}))

const Heading = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  width: '100%',
})

const Content = styled.div(props => ({
  display: props.hidden ? 'none' : 'block',
  marginBottom: '1rem',
}))

const ThumbContainer = styled.div(props => ({
  flexBasis: '8rem',
  marginRight: '1.5rem',
  justifyContent: 'center',
}))

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const Accordion = ({ data }) => {
  const [hidden, setHidden] = useState(true)
  const { header, content, thumb, noAccordion } = data

  return (
    <AccordionContainer>
      {thumb && (
        <ThumbContainer>
          <Image data={{ path: thumb }} />
        </ThumbContainer>
      )}
      <div css={{ flex: '1', flexBasis: '24rem' }}>
        <div onClick={() => setHidden(!hidden)}>
          <Heading>
            <RichText data={{ content: header }} />
            <div
              css={{
                height: '50px',
                width: '50px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {noAccordion ? null : hidden ? svgPlus : svgMinus}
            </div>
          </Heading>
        </div>
        <Content hidden={hidden}>
          <RichText data={{ content: content }} />
        </Content>
      </div>
    </AccordionContainer>
  )
}

export default Accordion
