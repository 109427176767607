/** @jsx jsx */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

const hslColorMatcher = /hsl\((\d+),\s*(\d+\.\d+)%,\s*(\d+\.\d+)/i

const mix = (color_1, color_2, weight) => {
  weight = typeof weight !== 'undefined' ? weight : 50 // set the weight to 50%, if that argument is omitted
  const inverseWeight = 1 - weight

  const [colorOneValues] = color_1.match(hslColorMatcher)
  const [, c1_hue, c1_saturation, c1_lightness] = color_1.match(hslColorMatcher)
  const [, c2_hue, c2_saturation, c2_lightness] = color_2.match(hslColorMatcher)
  const color = [
    c1_hue * weight + c2_hue * inverseWeight,
    (c1_saturation * weight + c2_saturation * inverseWeight).toFixed(3) + '%',
    (c1_lightness * weight + c2_lightness * inverseWeight).toFixed(3) + '%',
  ]

  return `hsl(${color.join(',')})` // PROFIT!
}

// returns the % of the element in the viewport
const isInViewport = (elem, wh) => {
  if (!elem) return 0
  const bounding = elem.getBoundingClientRect()

  if (bounding.top <= 0 && bounding.bottom > 0) {
    return (bounding.height - Math.abs(bounding.top)) / bounding.height
  }
  if (bounding.top < wh && bounding.bottom > 0) {
    return (bounding.height - (bounding.bottom - wh)) / bounding.height
  }

  return 0
}

const getVisibleSections = (wrapper, colors, wh) => {
  const visibleSections = [...wrapper.childNodes]
    .map(el => ({ el }))
    .reduce((acc, section, i) => {
      const { el } = section
      const newSection = {
        ...section,
        color: colors[i],
        percent: isInViewport(el, wh),
      }
      acc.push(newSection)
      return acc
    }, [])
    // percent must be greater than 0 for element to be visible
    .filter(({ percent }) => percent > 0)
  return visibleSections
}

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const List = ({ children, data }) => {
  const { colors, type } = data
  const ref = React.createRef()
  const theme = useTheme()

  useEffect(() => {
    if (!colors) return () => {}
    const handleScroll = () => {
      const { current: wrapper } = ref
      const wh = window.innerHeight
      const visibleSections = getVisibleSections(wrapper, colors, wh)

      if (visibleSections.length === 2) {
        // for 2 sections mix the colors based on the percentage visible of the first element
        const averageColor = mix(
          visibleSections[0].color,
          visibleSections[1].color,
          visibleSections[0].percent
        )
        wrapper.style.backgroundColor = averageColor
      } else if (visibleSections.length > 0) {
        // for 1 or more sections, use the section that is most prominent
        const section = visibleSections.find(
          sec =>
            sec.percent === Math.max(...visibleSections.map(x => x.percent))
        )
        wrapper.style.backgroundColor = section.color
      }
    }
    handleScroll() // set the bg color on first render
    // TODO: throttle this listener to 100ms to match the css transition
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [colors])
  return (
    <div
      ref={ref}
      css={mq({
        padding: theme.gutters.default,
        transition: 'background-color 100ms',
      })}
    >
      {children}
    </div>
  )
}

export default List
