/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const TextArea = props => {
  return <div>TextArea</div>
}

// /////////////////////////////////////////////////////////////////////////////
// Samples
// /////////////////////////////////////////////////////////////////////////////

const StyledComponent = styled(TextArea)(
  mq({
    color: 'hotpink',
  })
)

const FunctionalComponent = () => {
  const theme = useTheme()
  return <div css={mq({ color: 'blue' })}></div>
}

export default TextArea
