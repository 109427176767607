import React from 'react'

export function useFormAnalyzer() {
  const baseURL = 'accolade.com'
  const url = typeof window !== `undefined` ? window.location.href : baseURL

  const hasUTMs = url => url.includes('?')

  const getUTMs = (payload, url) => {
    const splitURL = url.split('?')
    const utms = splitURL[1].split('&')
    utms.forEach(utm => {
      const splitUTM = utm.split('=')
      payload[splitUTM[0]] = splitUTM[1]
    })
    return payload
  }

  const getReferralPayload = (payload, url) => {
    payload['type'] = 'referral'
    const splitURL = url.split('referral/')
    if (!hasUTMs(url)) {
      payload['companyName'] = splitURL[splitURL.length - 1]
    } else {
      const splitBySep = splitURL[1].split('?')
      payload['companyName'] = splitBySep[0]
    }
    return payload
  }

  const getContactPayload = (payload, url) => {
    const urlsplit = url.split('contact/')
    payload['type'] = 'contact'
    payload['subType'] = urlsplit[1]
    return payload
  }

  const contactPattern = new RegExp(`${baseURL}\/contact\/`, 'i')
  const referralPattern = new RegExp(`${baseURL}\/partners\/referral\/`, 'i')
  const utmPattern = new RegExp(`${baseURL}\/\?.*&+`, 'i')

  const urlpath = url.split('accolade.com/')
  let payload = {
    url: urlpath[1],
  }

  if (url.match(contactPattern)) payload = getContactPayload(payload, url)
  else if (url.match(referralPattern))
    payload = getReferralPayload(payload, url)
  if (url.match(utmPattern)) payload = getUTMs(payload, url)

  return payload
}
