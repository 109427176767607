/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import Img from 'gatsby-image'
import { useImages, mq } from '../../hooks'
import {TextLink} from "../RichText";

// ////////////////////////////////////////////////////////////////////////////
// Component
// ////////////////////////////////////////////////////////////////////////////

export const Image = ({ data }) => {
  const images = useImages()
  const { path } = data
  const imgStyle = data.imgStyle || {}
  const textDescription = data.textDescription
  const containerStyle = data.containerStyle || {}

  let component = <></>;

  if (path) {
    // const hasGatsbyFluid =
    const isExternal = path.toLowerCase().startsWith('http')
    const isStatic = path.toLowerCase().startsWith('/static')
    const inGraph = !!images[path]
    const hasGatsbyImageData = !!(
      images[path] &&
      images[path].childImageSharp &&
      images[path].childImageSharp.fluid
    )

    const extractedAlt = path.endsWith('/')
      ? path
        .split('/')
        .slice(-2)[0]
        .split('.')[0]
      : path
        .split('/')
        .slice(-1)[0]
        .split('.')[0]

    if (isExternal) {
      component =  (
        <div css={mq({ display: 'block', margin: 'auto', ...containerStyle })}>
          <img
            css={mq(imgStyle)}
            src={path}
            alt={extractedAlt}
            loading="eager"
          />
          <p>{textDescription}</p>
        </div>
      )
    } else if (!inGraph) {
      const staticPath = `/static/${path}`
      component =  (
        <div css={mq({ display: 'block', margin: 'auto', ...containerStyle })}>
          <img
            css={mq(imgStyle)}
            src={staticPath}
            alt={extractedAlt}
            loading="eager"
          />
          <p>2{textDescription}</p>
        </div>
      )
    } else if (isStatic || !hasGatsbyImageData) {
      component =  (
        <div css={mq({ display: 'block', margin: 'auto', ...containerStyle })}>
          <img
            css={mq(imgStyle)}
            src={images[path].publicURL}
            alt={extractedAlt}
            loading="eager"
          />

          <p
            style={
              textDescription
                ? { textAlign: 'center', padding: 20, marginBottom: 10 }
                : { display: 'none' }
            }
          >
            {textDescription}
          </p>
        </div>
      )
    } else if (hasGatsbyImageData && inGraph) {
      const fluidObj = images[path].childImageSharp.fluid
      component =  (
        <div
          css={mq({
            display: 'block',
            margin: 'auto',
            width: '100%',
            ...containerStyle,
          })}
        >
          <Img
            fluid={fluidObj}
            alt={extractedAlt}
            css={mq({ flex: '1', ...imgStyle })}
          />
        </div>
      )
    } else {
      let error = {
        module: 'Image',
        status: 'Error',
        message: 'UnHandled',
        data: data,
      }
      component =  <></>
    }
  } else {
    let error = {
      module: 'Image',
      status: 'Error',
      message: 'Image Path is undefined',
      data: data,
    }
    component =  <></>
  }

  if (data.linkUrl) {
      return <TextLink link={data.linkUrl}>{component}</TextLink>
  }

  return component
}

// Critical: provides consistency cross builds
Image.displayName = 'image'

export default Image
