/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

import savingsImage from '../../../images/aon-campaign/aon-savings.png'
import categoriesImage from '../../../images/aon-campaign/aon-categories.png'
import highCostImage from '../../../images/aon-campaign/aon-high-cost.png'
import overviewImage from '../../../images/aon-campaign/aon-overview.png'

import savingsMobileImage from '../../../images/aon-campaign/aon-savings--mobile.png'
import categoriesMobileImage from '../../../images/aon-campaign/aon-categories--mobile.png'
import highCostMobileImage from '../../../images/aon-campaign/aon-high-cost--mobile.png'
import overviewMobileImage from '../../../images/aon-campaign/aon-overview--mobile.png'

const TABS = [
  {
    title: 'Savings',
    heading: 'Lower costs per member per year (PMPY)',
    body: `Employers using Accolade Total Health and Benefits had significantly lower healthcare claims costs after implementing Accolade. In the first year with Accolade in 2019, employers saw a healthcare cost trend of 0% (flat) compared to 8.3% trend from the market control.
 
Aon validates that claims costs for these employers dropped from $590 Per Employee Per Year (PEPY) above the market control, to $432 PEPY below market control in the first year.
    
Customers implementing Accolade in 2018 saw an annualized healthcare cost trend of 2% compared to 6.1% from the market control over a two-year period.
    
The two-year employer group saw a 5% trend reduction in the first year after implementing Accolade, and a 3.2% trend reduction in year two.`,
    desktopImage: savingsImage,
    mobileImage: savingsMobileImage,
  },
  {
    title: 'Categories',
    heading: 'Reduced claims costs across key clinical categories',
    body: `Accolade 2019 implementations saw between 5% and 12% cost reductions per member for the most prevalent clinical conditions – such as mental health, disc/musculoskeletal disorders, hypertension and diabetes – compared to the market control.`,
    desktopImage: categoriesImage,
    mobileImage: categoriesMobileImage
  },
  {
    title: 'High cost',
    heading:
      'Lower claims costs across the population, from zero to four+ conditions and high-cost members',
    body: `Accolade customers experienced compounded savings as healthcare costs, needs and conditions mounted. Customers saw lower average spend for members managing zero to more than four chronic conditions. Reductions occurred for healthy members with no medical conditions to those with complex medical needs, such as those in the top 1% to 25% of spend.`,
    desktopImage: highCostImage,
    mobileImage: highCostMobileImage
  },
  {
    title: 'Overview',
    heading: 'Healthcare advocacy industry’s most rigorous independent study',
    body: `All six customers in the Aon study met the following report criteria: Fewer than 50,000 employees, service launch in January with a full year of data, and implementation of the complete Accolade Total Health and Benefits solution.`,
    desktopImage: overviewImage,
    mobileImage: overviewMobileImage
  },
]

const AONCampaignTabButton = styled.button`
  color: #828282;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1.125em;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  border: 2px solid transparent;
  
  flex-shrink: 0;
  
  &:hover {
    color: #40464B;
  }

  ${(props) =>
    props.current &&
    css`
      border-color: #003da6;
      color: #40464B;
      outline: none !important;
    `}
`

const AONCampaignHeading = styled.h4`
  color: #40464b;
  box-sizing: border-box;
  font-size: 1.125em;
  line-height: 1.5em;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 1.5em;
`

const AONCampaignBody = styled.div`
  color: #40464b;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5em;
  font-family: 'Open Sans', sans-serif;
`

const AONCampaignImageContainer = styled.div`
  padding: 32px 16px;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  width: 700px;
  flex-shrink: 0;
`

const AONNavigation = styled.div``;

const AONNavigationContainer = styled.div``;

export const AONCampaignTabs = ({ data }) => {
  const [currentTab, setCurrentTab] = useState(TABS[0])

  return (
    <div
      css={mq({
        backgroundColor: '#F9F9FB',
        paddingTop: ['45px', '45px', '100px'],
        paddingBottom: ['44px', '44px', '160px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: ['14px', '14px', '14px', '16px'],
      })}
    >
      <div css={mq({ maxWidth: '1220px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: ['center', 'center', 'center', 'normal'] })}>
        <AONNavigationContainer>
          <AONNavigation
            css={mq({
              width: ['100vw', '100vw', '700px'],
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: ['20px', '20px', '62px'],
              overflowX: 'auto',
            })}
          >
            {TABS.map((tab, index) => {

              const first = index === 0;
              const current = tab.title === currentTab.title

              return (
                <AONCampaignTabButton
                  current={current}
                  key={tab.title}
                  onClick={() => setCurrentTab(tab)}
                  css={mq({
                    marginRight: ['1.5em', '1.5em', 0],
                    marginLeft: first ? ['1.5em', '1.5em', '0'] : [0],
                  })}
                >
                  {tab.title}
                </AONCampaignTabButton>
              )
            })}
          </AONNavigation>
        </AONNavigationContainer>
        <div css={mq({ display: 'flex', alignItems: 'center', flexDirection: ['column', 'column', 'column', 'row'] })}>
          <AONCampaignImageContainer
            css={mq({
              width: ['100vw', '100vw', '700px']
            })}
          >
            <img src={currentTab.desktopImage} alt={`${currentTab.title} graph`} css={mq({ display: ['none', 'none', 'block'] })} />
            <img src={currentTab.mobileImage} alt={`${currentTab.title} graph`} css={mq({ display: ['block', 'block', 'none'] })} />
          </AONCampaignImageContainer>

          <div
            css={mq({
              flex: 'auto',
              marginLeft: [0, 0, 0, '84px'],
              marginTop: ['18px', '18px', '62px', 0],
              width: ['100vw', '100vw', '700px', 'auto'],
              padding: ['0 30px', '0 30px', 0],
            })}
          >
            <AONCampaignHeading>{currentTab.heading}</AONCampaignHeading>
            <AONCampaignBody>{currentTab.body}</AONCampaignBody>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AONCampaignTabs
