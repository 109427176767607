/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'
import { RichText } from '../RichText'
import { Image } from '../Image'
import {
  Display,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  TitleXs,
  TitleXxs,
  Body,
  BodySmall,
  Caption,
  TextLink,
  ButtonLink,
  OrderedList,
  OrderedListItem,
  UnorderedList,
  UnorderedListItem,
  Bold,
  Italic,
  Underline,
  SuperScript,
  Quote,
  Attribution,
} from '../RichText'
// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

const LeaderCard = styled.article(props =>
  mq({
    display: 'flex',
    flexFlow: ['column nowrap', 'row wrap'],
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'center',
    backgroundColor: '#ffffff',
    filter: `drop-shadow(0 0 20px ${props.theme.colors.scaled.grays._03})`,
    margin: '1rem 0',
    width: 'auto',
    height: '100%',
    color: props.theme.type.colors.gray.overLight,
  })
)

const LeaderThumb = styled.div(props => mq({ flexBasis: ['100%', '40%'] }))

const LeaderContent = styled.div(props =>
  mq({
    flex: '1',
    margin: '1rem 2rem',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  })
)

const LeaderQuote = styled.p(props => ({
  color: props.theme.colors.primary.blue,
  fontSize: '1rem',
  fontWeight: '300',
  fontStyle: 'italic',
  textAlign: 'center',
  margin: '3rem 0 0 1rem',
}))

export const Leader = ({ data }) => {
  const { name, title, quote, photo, bio, grouping } = data
  const theme = useTheme()
  const [hidden, setHidden] = useState(true)
  const richTextBio = <RichText data={{ content: bio }} />

  return (
    <LeaderCard>
      {grouping === 'Management' && (
        <LeaderThumb>
          <Image data={{ path: photo }} />
          {!hidden && (
            <div style={{ minWidth: '280px' }}>
              <LeaderQuote>{quote}</LeaderQuote>
            </div>
          )}
        </LeaderThumb>
      )}
      <LeaderContent>
        {title && <TitleXxs css={{ margin: '0' }}>{title}</TitleXxs>}
        {name && (
          <TitleSmall css={{ margin: '0', flex: '1' }}>{name}</TitleSmall>
        )}
        {hidden && quote && (
          <p
            css={{
              ...theme.type.styles.light,
              margin: '1em 0',
              color: theme.type.colors.gray.overLight,
            }}
          >
            {quote}
          </p>
        )}
        {!hidden && richTextBio}
        <div
          css={{
            marginTop: 'auto',
            cursor: 'pointer',
            color: theme.colors.primary.actionBlue,
          }}
          onClick={() => setHidden(!hidden)}
        >
          {hidden ? 'Show more' : 'Show less'}
        </div>
      </LeaderContent>
    </LeaderCard>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// Samples
// /////////////////////////////////////////////////////////////////////////////

export default Leader
