/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { Display, TitleMedium, Body } from '../RichText'
import { useDateFormatter, mq } from '../../hooks'
import { Image } from '../Image'
// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////
const ImageContainer = styled.div(props => ({
  width: '10rem',
  overflow: 'hidden',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  margin: 'auto',
  '.gatsby-image-wrapper': {
    flex: '1',
    opacity: '0.9',
  },
}))

const EventDetails = styled.div(props => ({
  overflow: 'hidden',
  display: 'flex',
  flexFlow: 'row wrap',
  margin: 'auto',
  justifyContent: 'space-around',
  borderTop: '1px solid',
  borderBottom: '1px solid',
  padding: '2rem',
}))

const EventDetail = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  padding: '1rem',
}))

const EventHeadings = ({ heading, subHeading }) => (
  <div
    css={{
      margin: '2rem 3rem',
      textAlign: 'center',
    }}
  >
    <Display>{heading}</Display>
    <TitleMedium>{subHeading}</TitleMedium>
  </div>
)

const EventDate = ({ date }) => (
  <EventDetail>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.5"
      height="22.5"
      viewBox="0 0 23.5 22.5"
    >
      <g
        id="Group_743"
        data-name="Group 743"
        transform="translate(-0.35 -0.65)"
      >
        <rect
          id="Rectangle_217"
          data-name="Rectangle 217"
          width="2"
          height="1"
          transform="translate(17.1 17.4)"
        />
        <rect
          id="Rectangle_218"
          data-name="Rectangle 218"
          width="22"
          height="18"
          transform="translate(1.1 4.4)"
          stroke-width="1.5"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          fill="none"
        />
        <line
          id="Line_53"
          data-name="Line 53"
          y1="3"
          transform="translate(17.1 1.4)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
        <line
          id="Line_54"
          data-name="Line 54"
          y1="3"
          transform="translate(7.1 1.4)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
        <line
          id="Line_55"
          data-name="Line 55"
          x2="22"
          transform="translate(1.1 8.4)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
      </g>
    </svg>

    <div css={{ paddingLeft: '1rem' }}>{date}</div>
  </EventDetail>
)

const EventLocation = ({ location }) => (
  <EventDetail>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.5"
      height="22.5"
      viewBox="0 0 17.5 22.5"
    >
      <g id="pin-3" transform="translate(-3.25 -0.25)">
        <path
          id="Path_509"
          data-name="Path 509"
          d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9a7.883,7.883,0,0,1,8-8A7.883,7.883,0,0,1,20,9Z"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
        <circle
          id="Ellipse_3031"
          data-name="Ellipse 3031"
          cx="3"
          cy="3"
          r="3"
          transform="translate(9 6)"
          stroke-width="1.5"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          fill="none"
        />
      </g>
    </svg>
    <div css={{ paddingLeft: '1rem' }}>{location}</div>
  </EventDetail>
)

const EventTime = ({ time }) => (
  <EventDetail>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.5"
      height="23.5"
      viewBox="0 0 23.5 23.5"
    >
      <g
        id="Group_745"
        data-name="Group 745"
        transform="translate(-0.25 -0.25)"
      >
        <circle
          id="Ellipse_3030"
          data-name="Ellipse 3030"
          cx="11"
          cy="11"
          r="11"
          transform="translate(1 1)"
          stroke-width="1.5"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          fill="none"
        />
        <path
          id="Path_508"
          data-name="Path 508"
          d="M12,6v6h6"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
      </g>
    </svg>
    <div css={{ paddingLeft: '1rem' }}>{time}</div>
  </EventDetail>
)

export const EventHeader = ({ data }) => {
  const {
    thumbnail,
    subHeading,
    heading,
    eventLocation,
    eventTime,
    eventDate,
  } = data
  const cleanDate = useDateFormatter(eventDate)

  return (
    <section>
      <ImageContainer>
        {thumbnail && <Image data={{ path: thumbnail }} />}
      </ImageContainer>
      <EventHeadings heading={heading} subHeading={subHeading} />
      <EventDetails>
        {eventDate ? <EventDate date={cleanDate}></EventDate> : null}
        {eventLocation ? (
          <EventLocation location={eventLocation}></EventLocation>
        ) : null}
        {eventTime ? <EventTime time={eventTime}></EventTime> : null}
      </EventDetails>
    </section>
  )
}

export default EventHeader
