import React from 'react'
import { useField, Field } from 'formik'
import { Input, Select, Textarea, DivRequired } from './FormStyles'
import { Body } from '../../components/RichText'

export const FormInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const valid = meta.touched && meta.error
  return (
    <>
      <Body htmlFor={props.id || props.name}>{label}</Body>
      <Input valid={valid} {...field} {...props} />
      {valid ? <DivRequired>{meta.error}</DivRequired> : null}
    </>
  )
}

export const FormSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const valid = meta.touched && meta.error
  return (
    <>
      <Body htmlFor={props.id || props.name}>{label}</Body>
      <Select valid={valid} {...field} {...props} />
      {valid ? <DivRequired>{meta.error}</DivRequired> : null}
    </>
  )
}

export const FormTextarea = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <Body htmlFor={props.id || props.name}>{label}</Body>
      <Textarea {...field} {...props} />
    </>
  )
}

export const FormCheckBox = ({ label, message, ...props }) => {
  const [field, meta] = useField(props)
  const valid = meta.touched && meta.error
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Field
          style={{ marginRight: '1rem' }}
          type="checkbox"
          name={props.name}
        />
        <Body htmlFor={props.id || props.name}>{label}</Body>
      </div>
      {valid ? <DivRequired>{meta.error}</DivRequired> : null}
      <span style={{ fontSize: '12px' }}>
        By submitting this form, I agree to receive periodic emails from
        Accolade related to products and services and can unsubscribe at any
        time. View{' '}
        <a href="https://www.accolade.com/legal/policies/privacy">
          Privacy Policy
        </a>
        .
      </span>
    </div>
  )
}
