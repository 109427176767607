/** @jsx jsx */
import React, { Children } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useBackgroundColors } from '../../hooks'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Helpers
// /////////////////////////////////////////////////////////////////////////////
const ImageColumn = styled.div(props =>
  mq({
    width: props.imgContainerStyle.width
      ? props.imgContainerStyle.width
      : ['75%', '65%', '55%'],
    alignSelf: props.imgContainerStyle.alignSelf
      ? props.imgContainerStyle.alignSelf
      : ['flex-bottom'],
    position: props.imgContainerStyle.position
      ? props.imgContainerStyle.position
      : ['initial'],
    right: props.imgContainerStyle.right
      ? props.imgContainerStyle.right
      : ['initial'],
    top: props.imgContainerStyle.top
      ? props.imgContainerStyle.top
      : ['initial'],
  })
)

const TextColumn = styled.div(props =>
  mq({
    width: ['100%', '100%', '55%'],
    display: ['none', 'none', 'block'],
    padding: props.alignment == 'left' ? '0 4rem 4rem 0' : '0 0 4rem 4rem',
    alignSelf: 'center',
  })
)

const SingleColumn = styled.div(props =>
  mq({
    width: '80%',
    padding: '3rem 0',
    alignSelf: 'baseline',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })
)

const OverflowContainer = styled.div(props =>
  mq({
    display: ['block', 'block', 'none'],
    padding: ['0 1.5rem', '0 3rem', '0 6rem', '0 12rem'],
    color: 'black',
    alignSelf: 'flex-end',
  })
)

// /////////////////////////////////////////////////////////////////////////////
// Inner Component
// /////////////////////////////////////////////////////////////////////////////

const InnerContainer = ({ innerData, imgContainerStyle = {} }) => {
  const data = innerData
  const { innerStyle } = data

  switch (innerStyle) {
    case '1Column':
      return <SingleColumn>{data.column1.component}</SingleColumn>
    case '2Column':
      const { name: name1, component: component1 } = data.column1
      const { name: name2, component: component2 } = data.column2
      // console.log('NAME 1' + name1)
      // console.log('NAME 2' + name2)
      const col1 =
        name1 === 'image' || name1 === 'AnimatedGIF' || name1 === 'default' ? (
          <ImageColumn alignment="left" imgContainerStyle={imgContainerStyle}>
            {component1}
          </ImageColumn>
        ) : (
          <TextColumn alignment="left">{component1}</TextColumn>
        )
      const col2 =
        name2 === 'image' || name2 === 'AnimatedGIF' || name2 === 'default' ? (
          <ImageColumn alignment="right" imgContainerStyle={imgContainerStyle}>
            {component2}
          </ImageColumn>
        ) : (
          <TextColumn alignment="right">{component2}</TextColumn>
        )

      return (
        <>
          {col1}
          {col2}
        </>
      )

    default:
      return <></>
  }
}

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const Hero = ({ data, children }) => {
  // Init
  const theme = useTheme()
  const { backgroundColor, borderRadius, imgContainerStyle, containerStyle } = data
  const backgroundSchema = useBackgroundColors(backgroundColor)
  // Process children for placement and componentry
  // Driven by child component type.displayName
  const totalChildren = Children.count(children)
  const innerData = {
    totalChildren: totalChildren,
    innerStyle: totalChildren > 1 ? '2Column' : '1Column',
  }

  Children.forEach(children, (child, index) => {
    const keyName = `column${index + 1}`
    const childType = child.type.displayName
      ? child.type.displayName
      : 'default'

    innerData[keyName] = {
      name: childType,
      component: child,
    }
    if (totalChildren > 1 && childType === 'richtext') {
      innerData['overflow'] = child
    }
  })

  return (
    <section>
      <div style={containerStyle}
        css={mq({
          ...backgroundSchema,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
          padding: theme.gutters.hero,
          borderRadius: borderRadius ? borderRadius : '0 0 50% 50%',
          width: '150vw',
          margin: '0 -25vw 0 -25vw',
          minHeight: '300px',
        })}
      >
        <InnerContainer
          innerData={innerData}
          imgContainerStyle={imgContainerStyle}
        />
      </div>
      {innerData.overflow && (
        <OverflowContainer>{innerData.overflow}</OverflowContainer>
      )}
    </section>
  )
}

export default Hero
