export const getResourceDataFromContentfulResource = (resource) => {

    const { author, heading, thumbnail, resourceURI, resourceType, eventDate, eventLocation, eventTime, resourceAsset } = resource;

    let normalizedResourceURI = resourceAsset?.file?.url || resourceURI;

    if (normalizedResourceURI?.startsWith('//')) normalizedResourceURI = 'https:' + normalizedResourceURI;

    return {
        author: author,
        thumbnail: "https:" + thumbnail.file.url,
        title: heading,
        heading: heading,
        resourceURI: normalizedResourceURI,
        resourceType: resourceType,
        eventDate: eventDate,
        eventLocation: eventLocation,
        eventTime: eventTime
    }
}

export default getResourceDataFromContentfulResource
