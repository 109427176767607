/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'
import { SocialIcons } from '../SocialIcons'

export const SocialShare = ({ slug, title }) => {
  const accBaseUrl = 'https://www.accolade.com'

  const facebookLink = `http://www.facebook.com/sharer.php?u=${encodeURI(
    accBaseUrl.concat(
      slug,
      '?utm_source=facebook&utm_medium=social&utm_medium=social-share'
    )
  )}&t=${encodeURI(title)}`

  const twitterLink = `https://twitter.com/intent/tweet?url=${encodeURI(
    accBaseUrl.concat(
      slug,
      '?utm_source=facebook&utm_medium=social&utm_medium=social-share'
    )
  )}&text=${encodeURI(title)}`

  const linkedinLink = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
    accBaseUrl.concat(
      slug,
      '?utm_source=linkedin&utm_medium=social&utm_medium=social-share'
    )
  )}&title=${encodeURI(title)}`

  return (
    <div
      css={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <a href={facebookLink} css={{ marginRight: '.5rem' }}>
        <SocialIcons network="Facebook" color="dark" />
      </a>
      <a href={twitterLink} css={{ marginRight: '.5rem' }}>
        <SocialIcons network="Twitter" color="dark" />
      </a>
      <a href={linkedinLink} css={{ marginRight: '.5rem' }}>
        <SocialIcons network="LinkedIn" color="dark" />
      </a>
    </div>
  )
}
