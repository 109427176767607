import 'normalize.css'
import './accdotcom.css'
import PlushCareBackground from '../../images/pages/home/PlushCare.png'
// ////////////////////////////////////////////////////////////////////////////
// Branding Configs:
// ////////////////////////////////////////////////////////////////////////////

const brandColors = {
  base: {
    black: '#000000',
    white: '#ffffff',
  },
  primary: {
    actionBlue: '#1079BC',
    blue: '#003da6',
    gray: '#6c757e',
    green: '#48d597',
    greenDark: '#00A37C',
    orange: '#e0722b',
    darkblue: '#131E46',
    lightblue: '#406DBC'
  },
  secondary: {
    blueDark: '#141f47',
    blueLight: '#57c1e8',
    cyan: '#00aeef',
    greenDark: '#00b388',
    greenLight: '#36E0B1',
    purple: '#662569',
    yellow: '#ffce00',
    yellowDark: '#ffbb1c',
    darkblue: '#131E46',
    lightblue: '#406DBC'
  },
  tertiary: {
    transparent: 'transparent',
  },
  products: {
    atb: '#5BC2E7',
    atc: '#48d597',
    athab: '#833D86',
    rcare: '#003da6',
    greenHeader: '#00A37C',
    darkblue: '#131E46',
    lightblue: '#406DBC',
  },
  fills: {
    _01: '#ffffff',
    _02: '#f9f9fb',
    _03: '#f3f4f7',
    _04: '#e1e3e5',
  },
  lines: {
    _01: '#e1e3e5',
    _02: '#c4c7cb',
    _03: '#a6acb1',
    _04: '#899097',
    _05: '#565d64',
  },
  scaled: {
    grays: {
      _01: '#f9f9fb',
      _02: '#f3f4f7',
      _03: '#e1e3e5',
      _04: '#c4c7cb',
      _05: '#a6acb1',
      _06: '#899097',
      _07: '#565d64',
      _08: '#40464b',
      _09: '#2b2e32',
      _10: '#151719',
    },
    blues: {
      _01: 'rgba(0, 61, 166, .1)',
      _02: 'rgba(0, 61, 166, .2)',
      _03: 'rgba(0, 61, 166, .3)',
      _04: 'rgba(0, 61, 166, .4)',
      _05: 'rgba(0, 61, 166, .5)',
      _06: 'rgba(0, 61, 166, .6)',
      _07: 'rgba(0, 61, 166, .7)',
      _08: 'rgba(0, 61, 166, .8)',
      _09: 'rgba(0, 61, 166, .9)',
      _10: 'rgba(0, 61, 166, 1)',
    },
  },
}

const backgroundColorSchema = {
  _default: {
    backgroundColor: 'none',
    color: brandColors.scaled.grays._08,
    ctaBgColor: brandColors.scaled.grays._08,
  },
  button: {
    primary: {
      backgroundColor: brandColors.primary.actionBlue,
      color: brandColors.base.white,
    },
    secondary: {
      backgroundColor: brandColors.primary.gray,
      color: brandColors.base.gray,
    },
    tertiary: {
      borderColor: brandColors.base.white,
      color: brandColors.base.white,
      backgroundColor: brandColors.tertiary.transparent,
    },
  },
  blue: {
    backgroundColor: brandColors.primary.blue,
    color: brandColors.base.white,
    ctaBgColor: brandColors.primary.blue,
    accLogo: 'whiteWhite',
  },
  'light blue': {
    backgroundColor: brandColors.secondary.blueLight,
    color: brandColors.base.white,
    ctaBgColor: brandColors.secondary.blueLight,
    accLogo: 'whiteWhite',
  },
  purple: {
    backgroundColor: brandColors.secondary.purple,
    color: brandColors.base.white,
    ctaBgColor: brandColors.secondary.purple,
    accLogo: 'whiteWhite',
  },
  green: {
    backgroundColor: brandColors.primary.green,
    color: brandColors.base.white,
    ctaBgColor: brandColors.primary.green,
    accLogo: 'whiteWhite',
  },
  greendark: {
    backgroundColor: brandColors.primary.greenDark,
    color: brandColors.base.white,
    ctaBgColor: brandColors.primary.green,
    accLogo: 'whiteWhite',
  },
  darkblue: {
    backgroundColor: brandColors.primary.darkblue,
    color: brandColors.base.white,
    ctaBgColor: brandColors.primary.blue,
    accLogo: 'whiteWhite',
  },
  lightblue: {
    backgroundColor: brandColors.primary.lightblue,
    color: brandColors.base.white,
    ctaBgColor: brandColors.primary.lightblue,
    accLogo: 'whiteWhite',
  },
  gray: {
    backgroundColor: brandColors.scaled.grays._02,
    color: brandColors.scaled.grays._08,
    ctaBgColor: brandColors.scaled.grays._08,
    accLogo: 'greenBlue',
  },
  white: {
    backgroundColor: brandColors.base.white,
    color: brandColors.scaled.grays._08,
    ctaBgColor: brandColors.primary.actionBlue,
  },
  atb: {
    backgroundColor: brandColors.products.atb,
    color: brandColors.base.white,
    ctaBgColor: brandColors.products.atb,
    accLogo: 'whiteWhite',
  },
  atc: {
    backgroundColor: brandColors.products.atc,
    color: brandColors.base.white,
    ctaBgColor: brandColors.products.atc,
    accLogo: 'whiteWhite',
  },
  athab: {
    backgroundColor: brandColors.products.athab,
    color: brandColors.base.white,
    ctaBgColor: brandColors.products.athab,
    accLogo: 'whiteWhite',
  },
  greenHeader: {
    backgroundColor: brandColors.products.greenHeader,
    color: brandColors.base.white,
    ctaBgColor: brandColors.products.greenHeader,
    accLogo: 'whiteWhite',
  },
  gradientblue: {
    background: 'linear-gradient(180deg, #5FC3E7 0%, #133FA0 144.55%)',
    backgroundColor: '#5BC2E7',
    color: brandColors.base.white,
    ctaBgColor: '#5BC2E7',
    accLogo: 'whiteWhite',
  },
  skyblue: {
    background: '#009BD6',
    color: brandColors.base.white,
    ctaBgColor: 'transparent',
  },
  plushcareblue: {
    background: `url(${PlushCareBackground})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'center',
    backgroundColor: '#E6F0F8',
    backgroundPosition: '460px',
    color: brandColors.base.black,
    ctaBgColor: brandColors.primary.actionBlue,
  },
}

// ////////////////////////////////////////////////////////////////////////////
// TypeSystem Configs
// ////////////////////////////////////////////////////////////////////////////

const typeStyles = {
  light: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontWeight: '300',
    fontStyle: 'normal',
  },
  standard: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontWeight: '400',
    fontStyle: 'normal',
  },
  italic: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontWeight: '400',
    fontStyle: 'italic',
  },
  semibold: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontWeight: '600',
    fontStyle: 'normal',
  },
  button: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
  link: {
    fontStyle: 'normal',
    textDecoration: 'none',
  },
  ul: {
    listStyleType: 'disc',
  },
  ol: {
    listStyleType: 'decimal',
  },
  li: {
    display: 'list-item',
    margin: '1em 1rem 1em 2rem',
    paddingLeft: '1rem',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}

const typeScale_deprecated = {
  display: {
    ...typeStyles.semibold,
    fontSize: '2.5rem',
    lineHeight: '3.25rem',
    // margin: '2rem 0 1em 0',
  },
  titleLarge: {
    ...typeStyles.standard,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    margin: '0.75rem 0 .5rem 0',
    color: '#003da6',
  },
  titleMedium: {
    ...typeStyles.standard,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    // margin: '1em 0 .5em 0',
  },
  titleSmall: {
    ...typeStyles.standard,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    // margin: '.75em 0 .25em 0',
  },
  titleXs: {
    ...typeStyles.semibold,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    // margin: '1em 0 1em 0',
  },
  titleXxs: {
    ...typeStyles.semibold,
    ...typeStyles.uppercase,
    fontSize: '0.8rem',
    lineHeight: '1rem',
    textTransform: 'uppercase',
    // margin: '0.5em 0 0.5em 0',
  },
  body: {
    ...typeStyles.standard,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    margin: '1em 0',
  },
  bodySmall: {
    ...typeStyles.standard,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    // margin: '0.75rem 0',
  },
  footNote: {
    ...typeStyles.standard,
    fontSize: '0.7rem',
    lineHeight: '1rem',
    // margin: '0.75rem 0',
  },
  quote: {
    ...typeStyles.standard,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    // margin: '1rem 0',
  },
  attribution: {
    ...typeStyles.standard,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    // margin: '.8rem 0',
  },
  caption: {
    ...typeStyles.standard,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    // margin: '0.5rem 0',
  },
  navSmall: {
    ...typeStyles.standard,
    fontSize: '.75rem',
    lineHeight: '1.625rem',
    textDecoration: 'none',
    // margin: '1rem 0',
  },
}

const typeScale = {
  display: {
    comfy: {
      ...typeStyles.semibold,
      fontSize: '2.5rem',
      lineHeight: '3.25rem',
      margin: '2.5rem 0 1.25em 0',
    },
    compact: {
      ...typeStyles.semibold,
      fontSize: '2.5rem',
      lineHeight: '3.25rem',
      margin: '1rem 0 0.5rem 0',
    },
    standard: {
      ...typeStyles.semibold,
      fontSize: '2.5rem',
      lineHeight: '3.25rem',
      margin: '2rem 0 1em 0',
    },
  },
  titleLarge: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      margin: '1.5rem 0',
      color: '#003da6',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      margin: '0.5rem 0',
      color: '#003da6',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      margin: '1rem 0',
      color: '#003da6',
    },
  },
  titleMedium: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
      margin: '1rem 0',
    },
  },
  titleSmall: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      argin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      margin: '1rem 0',
    },
  },
  titleXs: {
    comfy: {
      ...typeStyles.semibold,
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.semibold,
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.semibold,
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      margin: '1rem 0',
    },
  },
  titleXxs: {
    comfy: {
      ...typeStyles.semibold,
      ...typeStyles.uppercase,
      fontSize: '0.8rem',
      lineHeight: '1rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.semibold,
      ...typeStyles.uppercase,
      fontSize: '0.8rem',
      lineHeight: '1rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.semibold,
      ...typeStyles.uppercase,
      fontSize: '0.8rem',
      lineHeight: '1rem',
      margin: '1rem 0',
    },
  },
  body: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '1rem 0',
    },
  },
  bodySmall: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      margin: '1rem 0',
    },
  },
  footNote: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '0.8rem',
      lineHeight: '1.25rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '0.5rem',
      lineHeight: '1.25rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '0.8rem',
      lineHeight: '1.25rem',
      margin: '1rem 0',
    },
  },
  quote: {
    comfy: {
      ...typeStyles.light,
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      margin: '1.5em 0',
    },
    compact: {
      ...typeStyles.light,
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      margin: '0.5em 0',
    },
    standard: {
      ...typeStyles.light,
      fontSize: '1.5rem',
      lineHeight: '1.3em',
      margin: '1.5em 0',
    },
  },
  attribution: {
    comfy: {
      ...typeStyles.light,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.light,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.light,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '1rem 0',
    },
  },
  caption: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      margin: '1rem 0',
    },
  },
  navSmall: {
    comfy: {
      ...typeStyles.standard,
      fontSize: '.75rem',
      lineHeight: '1.625rem',
      textDecoration: 'none',
      margin: '1.5rem 0',
    },
    compact: {
      ...typeStyles.standard,
      fontSize: '.75rem',
      lineHeight: '1.625rem',
      textDecoration: 'none',
      margin: '0.5rem 0',
    },
    standard: {
      ...typeStyles.standard,
      fontSize: '.75rem',
      lineHeight: '1.625rem',
      textDecoration: 'none',
      margin: '1rem 0',
    },
  },
}

const typeColors = {
  actionBlue: {
    overLight: brandColors.primary.blue,
    overDark: brandColors.base.white,
  },
  blue: {
    overLight: brandColors.primary.blue,
    overDark: brandColors.base.white,
  },
  lightGray: {
    overLight: brandColors.scaled.grays._05,
    overDark: brandColors.base.white,
  },
  gray: {
    overLight: brandColors.scaled.grays._08,
    overDark: brandColors.base.white,
  },
  darkGray: {
    overLight: brandColors.scaled.grays._09,
    overDark: brandColors.base.white,
  },
}

const type = {
  styles: typeStyles,
  scales: typeScale,
  colors: typeColors,
}

// ////////////////////////////////////////////////////////////////////////////
// Layout Configs
// ////////////////////////////////////////////////////////////////////////////

// Following Google's Material UI
const breakpoints = {
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

const gutters = {
  default1: [
    '2.75rem 2.25rem 3.25rem 2.25rem',
    '2.75rem 4rem 3.25rem 4rem',
    '3.5rem 10rem 5rem 10rem',
    '4.5rem 18rem 8rem 18rem',
  ],
  default: [
    '2.75rem 1.5rem 3.25rem 1.5rem',
    '2.75rem 3rem 3.25rem 3rem',
    '3.5rem 6rem 5rem 6rem',
    '4.5rem 12rem 8rem 12rem',
  ],
  normal: ['2.75rem 1.5rem', '2.75rem 3rem', '3.5rem 6rem', '4.5rem 12rem'],
  hero: [
    '.5rem 30vw 0 30vw',
    '.5rem 30vw 0 30vw',
    '.5rem 30vw 0 30vw',
    '.5rem 30vw 0 30vw',
  ],
  xOnly: ['0 1.5rem', '0 3rem', '0 6rem', '0 12rem'],
  yOnly: [
    '2.75rem 0 3.25rem 0',
    '2.75rem 0 3.25rem 0',
    '3.5rem 0 5rem 0',
    '4.5rem 0 8rem 0',
  ],
  x: {
    root: ['1.5rem', '3rem', '6rem', '12rem'],
  },
  y: {
    // root: ['3.75rem', '4.5rem', '5.25rem', '7.5rem'],
    // children: ['3rem', '4rem', '5rem', '6rem'],
    // siblings: ['2rem', '2rem', '4rem', '4rem'],
    // root: ['2.75rem', '3.5rem', '3.75rem', '4rem'],
    root: ['2rem', '3rem', '3.75rem', '4rem'],
    children: ['2rem', '3rem', '3.75rem', '4rem'],
    siblings: ['1rem', '1rem', '3rem', '3rem'],
  },
}

const spacing = {
  px: '1px',
  _3xs: '0.125rem',
  _2xs: '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  _2xl: '2.5rem',
  _3xl: '3rem',
  _4xl: '4rem',
  _5xl: '5rem',
  _6xl: '6rem',
  _7xl: '7rem',
  _8xl: '8rem',
  _9xl: '9rem',
  _10xl: '10rem',
}

const shapes = {
  buttons: {
    // height: '2.5rem',
    // minWidth: '10.25rem',
    borderRadius: '2.5rem',
    borderWidth: '2px',
    borderStyle: 'solid',
    padding: '1rem 2rem',
    maxWidth: ['100%', '100%', '30rem'],
    width: 'auto',
    textAlign: 'center',
  },
  cards: {
    borderRadius: '2px',
    border: '1px',
  },
  images: {
    borderRadius: '2px',
  },
  icons: {
    border: '1px',
  },
}

const events = {
  focus: {
    outline: '0',
    boxShadow: '0px 0px 0px 2px',
  },
  hover: {},
}

// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////

export const theme = {
  breakpoints: breakpoints,
  colors: brandColors,
  backgrounds: backgroundColorSchema,
  events: events,
  gutters: gutters,
  shapes: shapes,
  spacing: spacing,
  type: type,
}

export default theme
