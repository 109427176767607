import React, { useState, useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { Formik, Form } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { mq } from '../hooks'
import * as Yup from 'yup'
import {
  FormInput,
  FormSelect,
  FormTextarea,
  FormCheckBox,
} from './components/FormFields'
import { RichText } from '../components'
import { useFormAnalyzer } from './components/FormHooks'
import { Button } from './components/FormStyles'
import { AllForms } from './FormConfig'
import promise from 'es6-promise'
import 'isomorphic-fetch'
import { personalEmails } from './../exludedDomains'
import { normalizeFormConfigName } from "../lib/normalizeFormConfigName";
import {getClearbitData} from "../lib/clearbit";

const EMAIL_FORM_CONFIG = [
  {
    label: "Work email",
    name: "email",
    placeholder: "janedoe@email.com",
    required: true,
    type: "input",
    value: ""
  }
]

promise.polyfill()

async function postData(url = '', data = {}) {
  // Handle Attribution Sourcing
  const adcid = localStorage.getItem('adcid')
    ? localStorage.getItem('adcid')
    : uuidv4()
  const utms = localStorage.getItem('attribution')
    ? JSON.parse(localStorage.getItem('attribution')).utms
    : {}
  const activities = localStorage.getItem('activities')
    ? JSON.parse(localStorage.getItem('activities'))
    : []

  const resourceId = document.getElementById('resource-data')
    ? document.getElementById('resource-data').dataset.resourceId
    : null

  if (!localStorage.getItem('adcid')) {
    localStorage.setItem('adcid', adcid)
  }

  if (localStorage.getItem('activities')) {
    localStorage.removeItem('activities')
  }

  // Handle Post Data Normalization + Post
  const normalizedData = {
    adcid: adcid,
    timestamp: Date.now(),
    resourceId: resourceId,
    ...data,
    ...utms,
    activities: activities,
  }
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(normalizedData),
  })
  return await response.json()
}


export const AccForm = ({ formtype, thankyou, inquiryType, width, onSubmitSuccess, SubmitText = 'Submit' }) => {
  const [submitted, setSubmitted] = useState(false)
  const theme = useTheme()
  const formMeta = useFormAnalyzer()
  const formConfig = AllForms[normalizeFormConfigName(formtype)]
  const [cBitinitialValues, setCBitinitialValues] = useState(null);

  useEffect(() => {
    if (submitted) window !== `undefined` && formtype !== `emoForm` ? window.scrollTo(0, 0) : null
  }, [submitted])

  const handleEmailSubmit = async (email) => {
    const clearbitData = await getClearbitData(email);
    const clearbitInitalFormValues = extractFormValuesFromClearbitData(clearbitData);
    setCBitinitialValues(clearbitInitalFormValues);
  }


  const getInitialValues = () => {
    const initialValues = {}
    formConfig.forEach(section => {
      section.fields.forEach(f => (initialValues[f.name] = f.value))
    })
    return initialValues
  }

  const handleEmailBlur = async (e) => {
    await handleEmailSubmit(e.currentTarget.value)
  }

  const extractFormValuesFromClearbitData = (clearbitData) => {
    const clearbitInitialValues = {}
    const numberOfEmployees = clearbitData.company?.metrics.employees
    let employeesrange = null
    if (numberOfEmployees < 200) {
      employeesrange = '< 200'
    } else if (200 < numberOfEmployees && numberOfEmployees < 499) {
      employeesrange = '200 - 499'
    } else if (500 < numberOfEmployees && numberOfEmployees < 4999) {
      employeesrange = '500 - 4,999'
    } else if (5000 < numberOfEmployees && numberOfEmployees < 34999) {
      employeesrange = '5,000 - 34,999'
    } else if (numberOfEmployees > 35000) {
      employeesrange = '> 35,000'
    }

    formConfig.forEach(section => {
      section.fields.forEach(f => {
        if (f.name === 'phoneNumber') {
          clearbitInitialValues[f.name] = clearbitData.company?.phone || '';
        } else if (f.name === 'firstName') {
          clearbitInitialValues[f.name] = clearbitData.person?.name.givenName || '';
        } else if (f.name === 'lastName') {
          clearbitInitialValues[f.name] = clearbitData.person?.name.familyName || '';
        } else if (f.name === 'companyName') {
          clearbitInitialValues[f.name] = clearbitData.company?.name || '';
        } else if (f.name === 'companyState') {
          clearbitInitialValues[f.name] = clearbitData.company?.geo.state || 'Please select';
        } else if (f.name === 'numberemployees') {
          clearbitInitialValues[f.name] = employeesrange || 'Please select';
        } else if (f.name === 'job_title') {
          clearbitInitialValues[f.name] = clearbitData.person?.employment.title || '';
        } else if (f.name === 'email') {
          clearbitInitialValues[f.name] = clearbitData.person?.email || '';
        } else if (f.name === 'privacyPolicy') {
          clearbitInitialValues[f.name] = false
        }
      })
    })
    return clearbitInitialValues
  }

  const isRequired = required => (required ? 'Required' : null)

  const getFullValidationSchema = () => {
    const yupObject = {}
    formConfig.forEach(section => {
      section.fields.forEach(f => {
        if (f.name === 'phoneNumber') {
          yupObject[f.name] = Yup.string()
            .required(isRequired(f.required))
            .matches(
              /^[+]*[(]{0,1}[0-9]{1,4}[)]?[-\s\./0-9]*$/,
              'Please enter a valid phone number.'
            )
        } else if (f.name === 'email') {
          yupObject[f.name] = Yup.string()
            .matches(
              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              'Please enter a valid email address.'
            )
            .test('workEmail?', 'Please enter your business email', function (value) {
              let check;
              if (personalEmails.length > 0) {
                check = personalEmails.map((mail) => value.toLowerCase().includes(mail.toLowerCase()))
                return !check.includes(true)
              }
            }
            )
        } else if (f.type === 'input')
          yupObject[f.name] = Yup.string().required(isRequired(f.required))
        else if (f.type === 'textarea')
          yupObject[f.name] = Yup.string().required(isRequired(f.required))
        else if (f.type === 'checkbox')
          yupObject[f.name] = Yup.bool().oneOf(
            [true],
            'Accept privacy policy is required.'
          )
        else if (f.type === 'oneOf')
          yupObject[f.name] = Yup.string()
            .notOneOf(
              ['Please select'],
              'Please select an option from the drop-down list.'
            )
            .required(isRequired(f.required))
      })
    })
    return Yup.object(yupObject)
  }
  const getFriendlyName = (fieldName, option) => {
    if (option === 'Please select') return option
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }
    const dateFields = ['webinarDates']
    if (dateFields.includes(fieldName)) {
      let datestring = new Date(option).toLocaleDateString(undefined, options)
      datestring += ' @ 11am Pacific'
      return datestring
    }
    return option
  }
  const getFieldWidth = span => {
    if (span === 2) return ['100%', '100%', '100%', '100%']
    else return ['100%', '100%', '48%', '48%']
  }

  const getComponent = (f, fieldkey) => {
    const span = f.span !== undefined ? f.span : 1
    const fieldWidth = getFieldWidth(span)
    return (
      <div
        key={fieldkey}
        css={mq({
          display: 'flex',
          flexFlow: 'column wrap',
          margin: '.5rem',
          padding: '.5rem',
          width: fieldWidth,
        })}
      >
        {f.type === 'input' && f.name === 'email' ? (
          <FormInput
            name={f.name}
            type={f.type}
            label={f.label}
            span={span}
            placeholder={`${f.placeholder}`}
            onBlur={e => {
              handleEmailBlur(e)
            }}
          />
        ) : f.type === 'input' ? (
          <FormInput
            name={f.name}
            type={f.type}
            label={f.label}
            span={span}
            placeholder={`${f.placeholder}`}
          />
        ) : f.type === 'oneOf' ? (
          <FormSelect name={f.name} span={span} type={f.type} label={f.label}>
            {f.options.map((option, i) => {
              return i === 0 && f.name !== 'webinarDates' ? (
                <option key={i} defaultValue disabled value={option}>
                  {option}
                </option>
              ) : (
                <option key={i} value={option}>
                  {getFriendlyName(f.name, option)}
                </option>
              )
            })}
          </FormSelect>
        ) : f.type === 'textarea' ? (
          <FormTextarea
            name={f.name}
            type={f.type}
            label={f.label}
            span={span}
            placeholder={`${f.placeholder}`}
          />
        ) : f.type === 'checkbox' ? (
          <FormCheckBox
            name={f.name}
            type={f.type}
            label={f.label}
            message={f.message}
            span={span}
          />
        ) : null}
      </div>
    )
  }

  if (submitted) return (
    <div class="thankyou" style={{ paddingTop: '4rem', transition: '.6s ease-in' }}>
      <RichText key={1} data={{ content: thankyou }} />
    </div>
  )

  const form = (
    <Formik
      initialValues={cBitinitialValues ? cBitinitialValues : getInitialValues()}
      enableReinitialize
      validationSchema={getFullValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const payload = {
          ...values,
          ...formMeta,
          inquiryType: inquiryType ? inquiryType : 'Prospect',
        }

        postData(
          'https://pfrthrhkpg.execute-api.us-east-1.amazonaws.com/prod/accdotcom-forms',
          payload
        ).then(data => {
          onSubmitSuccess && (eval(onSubmitSuccess))();
        })

        setTimeout(() => {
          setSubmitting(false)
          setSubmitted(true)
        }, 400)
      }}
    >
      {({
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
      }) => (
        <Form
          css={mq({
            justifyContent: 'center',
            width: ['100%', '100%', '800px', '800px'],
          })}
          style={width}
        >
          {formConfig.map((section, index) => {
            const textProps = section.richText.data
            return (
              <div
                style={{
                  justifyContent: 'center',
                }}
                key={index}
              >
                <div
                  css={{
                    color: theme.colors.primary[textProps.color],
                  }}
                >
                  <RichText key={1} data={{ content: textProps.content }} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    ...section.richText.data.style,
                  }}
                >
                  {section.fields.map(f => getComponent(f, f.name))}
                  <div
                    css={mq({
                      display: 'flex',
                      flexFlow: 'column wrap',
                      margin: '1.5rem .5rem 0 .5rem',
                      padding: '1.5rem .5rem 0 .5rem',
                      alignContent: 'center',
                      width: getFieldWidth(1),
                    })}
                  >
                    <Button id={`acc-${formtype}`} type="submit">
                      {SubmitText}
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </Form>
      )}
    </Formik>
  )

  return (
    <div style={{ padding: theme.spacing._1xl }} id="form">
      {form}
    </div>
  )
}
export default AccForm