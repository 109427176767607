export const topNavigation = [
  {
    label: 'Solutions',
    link: '/solutions/',
    type: 'text',
    items: [],
  },
  {
    label: 'Results',
    link: '/results/',
    type: 'text',
    items: [],
  },
  {
    label: 'Company',
    link: '/company/',
    type: 'text',
    items: [],
  },
  {
    label: 'Government Solutions Landing',
    link: '/gov/',
    type: 'text',
    items: [],
  },
  {
    label: 'Government Solutions',
    link: '/industry/government/',
    type: 'text',
    items: [],
  },
  {
    label: 'Benefits Advisors',
    link: '/benefits-advisors/',
    type: 'text',
    items: [],
  },
  {
    label: 'Member Login',
    link: 'https://member.accolade.com/',
    type: 'text',
    items: [],
  },
]

export const lastItemRoundButton = [
  {
    default: {
      label: 'Schedule a Demo',
      link: '/demo/total-benefits/',
      type: 'button',
      items: [],
    },
    'industry/government': {
      url: '/industry/government/',
      label: 'Contact Us',
      link: '#form',
      type: 'button',
      items: [],
    }
  }
]

export const bottomNavigation = [
  {
    label: 'Solutions',
    link: '/solutions/',
    type: 'text',
    items: [
      {
        label: 'Accolade Total Benefits ',
        link: '/solutions/total-benefits/',
        type: 'text',
        items: [],
      },
      {
        label: 'Accolade Total Care',
        link: '/solutions/total-care/',
        type: 'text',
        items: [],
      },
      {
        label: 'Accolade Total Health and Benefits',
        link: '/solutions/total-health-and-benefits/',
        type: 'text',
        items: [],
      },
      {
        label: 'Accolade COVID Response Care',
        link: '/solutions/response-care/',
        type: 'text',
        items: [],
      },
      {
        label: 'Mental Health Integrated Care',
        link: '/solutions/mental-health/',
        type: 'text',
        items: [],
      },
      {
        label: 'Expert Medical Opinion',
        link: '/solutions/expert-medical-opinion',
        type: 'text',
        items: [],
      },
      {
        label: 'Health and Benefits Ecosystem',
        link: '/platform/ecosystem/',
        type: 'text',
        items: [],
      },
    ],
  },
  {
    label: 'Resources',
    link: '/resources/',
    type: 'text',
    items: [
      {
        label: 'All',
        link: '/resources/',
        type: 'text',
        items: [],
      },
      {
        label: 'Blog',
        link: '/blogs/',
        type: 'text',
        items: [],
      },
      {
        label: 'News',
        link: '/news/',
        type: 'text',
        items: [],
      },

      {
        label: 'Media Kit',
        link: '/media-kit/',
        type: 'text',
        items: [],
      },
      {
        label: 'Press Releases',
        link: '/press/',
        type: 'text',
        items: [],
      },
    ],
  },
  {
    label: 'Company',
    link: '/company/',
    type: 'text',
    items: [
      {
        label: 'Board of Directors',
        link: '/leadership#board-of-directors',
        type: 'text',
        items: [],
      },
      {
        label: 'Careers',
        link: '/careers/',
        type: 'text',
        items: [],
      },
      {
        label: 'Events',
        link: '/events/',
        type: 'text',
        items: [],
      },
      {
        label: 'Investors',
        link: 'https://ir.accolade.com',
        type: 'text',
        items: [],
      },
      {
        label: 'Leadership',
        link: '/leadership/',
        type: 'text',
        items: [],
      },
      {
        label: 'Medical Advisory Board',
        link: '/leadership#medical-advisory-board/',
        type: 'text',
        items: [],
      },
      {
        label: 'Social Impact',
        link: '/corporate-social-responsibility/',
        type: 'text',
        items: [],
      },
    ],
  },
  {
    label: 'Contact',
    link: '/contact/',
    type: 'text',
    items: [
      {
        label: 'Inquiries',
        link: '/contact/',
        type: 'text',
        items: [],
      },
      {
        label: 'Member Support',
        link: '/contact/',
        type: 'text',
        items: [],
      },
      {
        label: 'Office Locations',
        link: '/contact#our-locations/',
        type: 'text',
        items: [],
      },
    ],
  },
]

export const footerNavigation = [
  {
    label: 'Customer Login',
    link: 'https://iq.accolade.com/',
    type: 'text',
    items: [],
  },
  {
    label: 'Privacy Policy',
    link: '/legal/policies/privacy/',
    type: 'text',
    items: [],
  },
  {
    label: 'Terms of Use',
    link: '/legal/terms/',
    type: 'text',
    items: [],
  },
]

export const socialNavigation = [
  {
    label: 'Facebook',
    link: 'https://www.facebook.com/AccoladeInc/',
    type: 'icon',
    items: [],
  },
  {
    label: 'Twitter',
    link: 'https://twitter.com/accolade',
    type: 'icon',
    items: [],
  },
  {
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/company/accolade-inc-/',
    type: 'icon',
    items: [],
  },
  {
    label: 'Instagram',
    link: 'https://www.instagram.com/we_are_accolade/',
    type: 'icon',
    items: [],
  },
]
