/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import {
  Display,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  TitleXs,
  TitleXxs,
  Body,
} from '../RichText'
import { useDateFormatter, mq } from '../../hooks'
import { Image } from '../Image'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

const ImageContainer = styled.div(props => ({
  width: ['100%', '100%', '100%'],
  height: ['100%', '100%', '100%'],
  overflow: 'hidden',
  justifyContent: 'center',
  '.gatsby-image-wrapper': {
    flex: '1',
    opacity: '0.9',
  },
}))
const Outer = styled.div(props =>
  mq({
    width: ['100%', '100%', '100%'],
  })
)

const Container = styled.div(props =>
  mq({
    width: ['80%', '80%', '40%'],
    margin: '2rem auto 2rem auto',
    display: 'flex',
  })
)

export const PressNewsHeader = ({ data }) => {
  const { date, thumbnail } = data
  const cleanDate = useDateFormatter(date)

  return (
    <Outer>
      <Container>
        <ImageContainer>
          {thumbnail && <Image data={{ path: thumbnail }} />}
        </ImageContainer>
      </Container>
      {cleanDate && <TitleXxs spacing="compact">{cleanDate}</TitleXxs>}
    </Outer>
  )
}

// Currently already in Blocks in the RichText Component
// {title && <titleLarge spacing="compact">{title}</titleLarge>}
// {subTitle && <titleSmall spacing="compact">{subTitle}</titleSmall>}

export default PressNewsHeader
