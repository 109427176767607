/** @jsx jsx */
import React, { Children } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Inner Component
// /////////////////////////////////////////////////////////////////////////////

const ImageContainer = styled.div(props =>
  mq({
    width: ['70%', '70%', '45%'],
    ...props.padding,
  })
)

const TextContainer = styled.div(props =>
  mq({
    width: ['100%', '100%', '55%'],
    ...props.padding,
    //    padding: [
    //      '0',
    //      '0',
    //      props.alignment == 'left' ? '0 5rem 0 0' : '0 0 0 5rem',
    //      props.alignment == 'left' ? '0 6rem 0 0' : '0 0 0 6rem',
    //    ],
  })
)

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const SideBySide = ({ data, depth, children }) => {
  const theme = useTheme()
  const { containerStyle } = data
  const flexDirection =
    children[0].type.displayName == 'image' ? 'column' : 'column-reverse'
  const alignment = flexDirection == 'column' ? 'right' : 'left'

  const inner = Children.map(children, (child, i) => {
    const childType = child.type.displayName
    let padding = { paddingLeft: '0rem', paddingRight: '0rem' }
    if (i === 0) padding.paddingRight = '1rem'
    else padding.paddingLeft = '1rem'

    switch (childType) {
      case 'image':
        return <ImageContainer padding={padding}>{child}</ImageContainer>
      case 'richtext':
        return (
          <TextContainer padding={padding} alignment={alignment}>
            {child}
          </TextContainer>
        )
      default:
        return <></>
    }
  })

  return (
    <section
      style={{ ...containerStyle }}
      css={mq({
        display: 'flex',
        flexDirection: [flexDirection, flexDirection, 'row'],
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-around',
      })}
    >
      {inner}
    </section>
  )
}

export default SideBySide
