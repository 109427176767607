import React, { useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { jsx } from '@emotion/core'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const ListItem = ({ children }) => {
  const theme = useTheme()
  return <section>{children}</section>
}
export default ListItem
