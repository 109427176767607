export const getClearbitData = async (email) => {
    const response = await fetch(`https://accolade.com/api/clearbit/`, {
        method: 'GET',
        headers: {
            'x-email': email
        },
    })
    const data = await response.json();
    return data;
}
