import { useStaticQuery, graphql } from 'gatsby'
import makeSlugForContentfulPost from "../../lib/makeSlugForContentfulPost";
import { BLOGS, RESOURCES } from "../../lib/collectionNames";

export const useResources = () => {
  const data = useStaticQuery(graphql`
    query {
      allData: allResource(
        filter: { collection: { eq: "resources" } }
        sort: { order: DESC, fields: publishedDate }
      ) {
        nodes {
          id
          slug
          publishedDate(formatString: "MMMM D YYYY")
          data {
            resourceType
            thumbnail
            heading
            subHeading
            abstract
          }
        }
      }
      allContentfulResource: allContentfulResource(
        sort: { order: DESC, fields: publishedDate },
        filter: { title: { ne: "Dummy" } }
      ) {
          nodes {
            id
            slug
            publishedDate(formatString: "MMMM D YYYY")
            heading
            subHeading
            resourceType
            thumbnailImage {
              url
            }
            thumbnail{
              file{
                url
              }
            }
          }
      }
    }
  `)

  const { allData: localJSONData, allContentfulResource: allContentfulData } = data;

  const localJSONCards = localJSONData.nodes.map(resource => ({
    id: resource.id,
    slug: `${resource.slug}/`,
    thumbnail: resource.data.thumbnail,
    publishedDate: resource.publishedDate,
    title: resource.data.heading,
    abstract: resource.data.abstract,
    resourceType: resource.data.resourceType,
  }))

  const contentfulCards = allContentfulData.nodes.map(resource => {
    const slug = makeSlugForContentfulPost(resource, RESOURCES);

    return ({
      id: resource.id,
      slug: `${slug}/`,
      thumbnail: resource.thumbnailImage ? resource.thumbnailImage[0].url : "https:" + resource.thumbnail.file.url,
      publishedDate: resource.publishedDate,
      title: resource.heading,
      abstract: resource.subHeading,
      resourceType: resource.resourceType,
    });
  })

  const allCards = [...contentfulCards, ...localJSONCards];

  return allCards
}

export default useResources
