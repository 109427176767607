/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const CallToActions = ({ children }) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-around',
        margin: '2rem 0',
      }}
    >
      {children}
    </div>
  )
}

export default CallToActions
