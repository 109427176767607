/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq } from '../../hooks'

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const ImageOverlay = props => {
  return <div>ImageOverlay</div>
}

// /////////////////////////////////////////////////////////////////////////////
// Samples
// /////////////////////////////////////////////////////////////////////////////

const StyledComponent = styled(ImageOverlay)(
  mq({
    color: 'hotpink',
  })
)

const FunctionalComponent = () => {
  const theme = useTheme()
  return <div css={mq({ color: 'blue' })}></div>
}

export default ImageOverlay
