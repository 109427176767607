import React from 'react'
import { jsx } from '@emotion/core'
import ReactPlayer from 'react-player'

export const Video = props => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...props.data.containerStyle,
      }}
    >
      <ReactPlayer url={props.data.url} controls={true} />
    </div>
  )
}

export default Video
