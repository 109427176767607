import { useTheme } from 'emotion-theming'

export const useBackgroundColors = color => {
  const theme = useTheme()
  const backgroundColorSchema = theme.backgrounds
  const backgroundColor =
    color && color.toLowerCase() in backgroundColorSchema
      ? backgroundColorSchema[color.toLowerCase()]
      : { backgroundColor: 'none', color: 'inherit' }

  return backgroundColor
}

export default useBackgroundColors
