/** @jsx jsx */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
} from 'react-table'
import Pagination from 'react-js-pagination'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { Image } from '../Image'
import {
  mq,
  useBlogs,
  useBlogsEngineering,
  useEvents,
  useNews,
  usePress,
  useResources,
  useWebinars,
} from '../../hooks'
import {
  Body,
  TitleXs,
  TitleXxs,
  TitleMedium,
  TitleSmall,
  TextLink,
} from '../RichText'

// /////////////////////////////////////////////////////////////////////////////
// Slug Helper
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////
// Styled Components
// /////////////////////////////////////////////////////////////////////////////

const IndexCard = styled.article(props =>
  mq({
    backgroundColor: '#ffffff',
    filter: `drop-shadow(0 0 20px ${props.theme.colors.scaled.grays._03})`,
    margin: '2rem 0',
    width: 'auto',
    height: ['600px', '600px', '330px'],
    display: 'flex',
    flexFlow: ['column', 'column', 'row nowrap'],
    justifyContent: 'space-between',
  })
)

const ImageContainer = styled.div(props =>
  mq({
    height: ['20rem', '20rem', '330px'],
    width: ['100%', '100%', '20rem'],
    overflow: 'hidden',
    display: 'flex',
    flexFlow: ['column', 'row nowrap'],
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.gatsby-image-wrapper': {
      flex: '1',
      opacity: '0.9',
    },
  })
)

const ContentContainer = styled.div(props =>
  mq({
    flex: '1',
    margin: '2rem',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignContent: 'stretch',
  })
)

export const Arrow = () => {
  const theme = useTheme()
  return (
    <div
      css={{
        marginLeft: '1rem',
        width: '20px',
        color: theme.colors.primary.actionBlue,
      }}
    >
      <svg viewBox="0 0 20.243 13.501">
        <defs />
        <path
          fill="#1079bc"
          d="M12.909.258a.919.919 0 00-.007 1.294l4.275 4.282H.907a.914.914 0 000 1.828H17.17l-4.275 4.286a.925.925 0 00.007 1.294.91.91 0 001.287-.007l5.794-5.836a1.026 1.026 0 00.19-.288.872.872 0 00.07-.352.916.916 0 00-.26-.64L14.189.283a.9.9 0 00-1.28-.025z"
          data-name="Icon ionic-ios-arrow-round-forward"
        />
      </svg>
    </div>
  )
}

const Slug = ({ link, children }) => {
  const theme = useTheme()

  return (
    <div
      css={{
        color: theme.colors.primary.actionBlue,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TextLink link={link}>{children}</TextLink>
      <Arrow />
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

const ListingCard = ({ data }) => {
  const { thumbnail, publishedDate, title, abstract, slug, ctaText } = data

  return (
    <IndexCard>
      <ImageContainer>
        <Image data={{ path: thumbnail }} />
      </ImageContainer>
      <ContentContainer>
        <TitleXxs spacing="compact">{publishedDate}</TitleXxs>
        <TitleMedium spacing="compact">{title}</TitleMedium>
        <Body css={{ flex: '1', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {abstract}
        </Body>
        <Slug link={slug}>{ctaText}</Slug>
      </ContentContainer>
    </IndexCard>
  )
}

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length
  const theme = useTheme()

  return (
    <span>
      <input
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records`}
        style={{
          width: '339px',
          height: '49px',
          padding: '0.6em 2.5em 0.5em 0.8em',
          border: '1px solid #ccc',
          background: `#fff url(/static/p8vl2zej5t9u-dce2b9a4219887f4fdb3372ab0163d68.svg) center right ${theme.spacing.sm} no-repeat`,
        }}
      />
    </span>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
const SelectFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const selectStyles = {
    display: 'block',
    padding: '0.6em 1.4em 0.5em 0.8em',
    width: '339px',
    height: '49px',
    margin: '0',
    border: '1px solid #ccc',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    appearance: 'none',
    backgroundColor: '#fff',
    backgroundImage: `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')`,
    backgroundRepeat: 'no-repeat, repeat',
    backgroundPosition: 'right 0.7em top 50%, 0 0',
    backgroundSize: '0.65em auto, 100%',
  }

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      css={selectStyles}
    >
      <option value="">All {Header}s</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const dataHooks = {
  blogs: useBlogs,
  blogsEngineering: useBlogsEngineering,
  events: useEvents,
  news: useNews,
  press: usePress,
  webinars: useWebinars,
  resources: useResources,
}

const ctaMap = {
  blogs: 'Read the full story',
  blogsEngineering: 'Read the full story',
  news: 'Read the full story',
  press: 'Read the full story',
  events: 'Learn More',
  webinars: 'Register today',
  resources: 'Learn More',
}

export const Indexer = ({ data }) => {
  const { indexType, indexStyle } = data
  const theme = useTheme() // TODO: figure out why theme hook order can cause console errors
  const resourceHookData = dataHooks[indexType]()
  const resourceData = useMemo(() => resourceHookData, [])
  const hasResourceType = resourceData.some(resource => resource.resourceType)
  const columns = useMemo(
    () => [
      { accessor: 'title', disableFilters: true },
      { accessor: 'subHeading', disableFilters: true },
      { accessor: 'abstract', disableFilters: true },
      { accessor: 'publishData', disableFilters: true },
      {
        Header: 'Resource Type:',
        accessor: 'resourceType',
        disableFilters: true,
        ...(hasResourceType && {
          disableFilters: false,
          Filter: SelectFilter,
          filter: 'includes',
        }),
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    page,
    pageCount,
    state: { pageIndex, pageSize, globalFilter },
    gotoPage,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      data: resourceData,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )

  const listingsCards = resourceData.map(resource => {
    const cardData = { ...resource, ctaText: ctaMap[indexType] }
    return <ListingCard key={cardData.id} data={cardData} />
  })

  const paginationStyles = {
    '.pagination': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing._3xl,
      li: {
        margin: `0 ${theme.spacing.xs}`,
      },
      a: {
        ...theme.type.scales.body.standard,
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        transition: 'all 0.3s ease',
        borderRadius: theme.spacing._3xs,
      },
      '.active a, a:hover': {
        ...theme.backgrounds.button.primary,
      },
      '.disabled a, .disabled a:hover': {
        backgroundColor: 'inherit',
        color: theme.type.colors.gray.overLight,
        cursor: 'default',
      },
    },
  }

  const filterStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '> div': {
      margin: `0 ${theme.spacing.sm}`,
    },
  }

  const anchorId = 'resourceTable'

  // TODO: replace pagination prev/next text with svg from designs
  // TODO: ux-question - should clicking a new page go back to top of results?
  // TODO: ux-question - should there be pagination at the top?
  return (
    <div id={anchorId} {...getTableProps()} css={paginationStyles}>
      {
        <div id="filters" css={filterStyles}>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps()}>
                  <div>{column.canFilter ? column.render('Header') : null}</div>
                </div>
              ))}
            </div>
          ))}
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      }
      {page.length > 0 ? (
        <>
          <div {...getTableBodyProps()}>
            {page?.map(row => {
              prepareRow(row)
              const cardData = { ...row.original, ctaText: ctaMap[indexType] }
              return <ListingCard key={cardData.id} data={cardData} />
            })}
          </div>
          <Pagination
            activePage={pageIndex + 1}
            totalItemsCount={rows.length}
            pageRangeDisplayed={5}
            onChange={i => gotoPage(i - 1)}
            hideFirstLastPages={true}
            getPageUrl={() => `#${anchorId}`} // This default behavior gets prevented by pagination component
          />
        </>
      ) : (
          <Body>Sorry, there are no results.</Body> // TODO: this needs a comp
        )}
    </div>
  )
}

export default Indexer
